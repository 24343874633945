const actionTypes = {
  // Environment
  SET_COLUMNS: 'SET_COLUMNS',

  // Notifications and Errors
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  TOGGLE_NOTIFICATION_DETAILS: 'TOGGLE_NOTIFICATION_DETAILS',

  // Auth
  SET_SESSION_ID: 'SET_SESSION_ID',
  LOGIN_AND_GET_PATIENT: 'LOGIN_AND_GET_PATIENT',
  LOGIN_ENCRYPTED_GET: 'LOGIN_ENCRYPTED_GET',

  // HCC
  HCC_DATA_GET: 'HCC_DATA_GET',
  UPDATE_CONDITION: 'UPDATE_CONDITION',
  SAVE_CONDITION: 'SAVE_CONDITION',
  OPEN_CONDITION: 'OPEN_CONDITION',
  CLOSE_CONDITION: 'CLOSE_CONDITION',
  CHANGE_CONDITION_STEP: 'CHANGE_CONDITION_STEP',

  // Supporting Evidence
  GET_EVIDENCE_DOCUMENT: 'GET_EVIDENCE_DOCUMENT',

  // Review and Submission
  SUBMIT_FOR_REVIEW: 'SUBMIT_FOR_REVIEW',
  CLOSE_REVIEW: 'CLOSE_REVIEW',
  SIGN_AND_SUBMIT: 'SIGN_AND_SUBMIT',
  CLOSE_SUBMISSION_MODAL: 'CLOSE_SUBMISSION_MODAL',

  // Patient Summary
  PATIENT_SUMMARY_GET_DATA: 'PATIENT_SUMMARY_GET_DATA'
}

export default actionTypes

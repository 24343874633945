// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'
import SupportingEvidence from '../SupportingEvidence/SupportingEvidence'
import MultiStepReview from '../MultiStepWorkflow/Review/MultiStepReview'
import SingleStepReview from '../SingleStepWorkflow/Review/SingleStepReview'

// Action Creators
import { signAndSubmit } from '../../../store/actions/signAndSubmit'

class SecondaryColumn extends Component {
  // Determine which review components to use in the one-column modal view. Two-column review
  // components are handled up the tree in App, and are placed in the secondary column (right side).
  getReviewComponent(recommendedWorkflow) {
    if (recommendedWorkflow) {
      return (<MultiStepReview onSubmit={this.props.signAndSubmit} />)
    } else {
      return (<SingleStepReview onSubmit={this.props.signAndSubmit} />)
    }
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'getReviewComponent'
    ])
  }

  render() {
    const {
      recommendedWorkflow,
      recommendedActiveCondition,
      ciActiveCondition,
      recommendedConditions,
      ciConditions,
      isReviewingConditions
    } = this.props

    let activeCondition = recommendedActiveCondition
    if(!activeCondition) {
      activeCondition = ciActiveCondition
    }
    if (isReviewingConditions) {
      let reviewComponent = this.getReviewComponent(recommendedWorkflow)

      return (
        <section className='review-conditions-container'>
          <div className='review-conditions-padding'>
            {reviewComponent}
          </div>
        </section>
      )
    } else {
      if (recommendedConditions.length || ciConditions.length) {
        return (
          <SupportingEvidence condition={activeCondition}/>
        )  
      } else {
        return (
          <section className='supporting-evidence loading'></section>
        )
      }
    }
  }
}

const mapStateToProps = (state) => ({
  recommendedWorkflow: state.conditions.recommendedWorkflow,
  recommendedActiveCondition: state.conditions.recommended.active,
  ciActiveCondition: state.conditions.clinicalinference.active,
  recommendedConditions: state.conditions.recommended.list,
  ciConditions: state.conditions.clinicalinference.list,
  isReviewingConditions: state.reviewSubmission.isReviewingConditions
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    signAndSubmit: signAndSubmit
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryColumn)

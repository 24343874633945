// Modules
import React from 'react'

const AcceptedClaimIcon = props => {
  return (
      <div className='supporting-evidence-icon'>
        <svg width="24px" height="30px" viewBox="0 -1 11 15">
          <g stroke="#999999" strokeWidth="0.8" fill="none">
            <polygon points="9.30982368 1.71508544 9.30982368 12.7913486 0 12.7913486 0 0 7.50651388 0"></polygon>
            <path d="M1.16372796,3.04555919 L6.98236776,3.04555919 L1.16372796,3.04555919 Z"></path>
            <path d="M1.16372796,5.48200654 L6.40050378,5.48200654 L1.16372796,5.48200654 Z"></path>
          </g>
          <g id="Group-8" transform="translate(3, 6)"stroke="#999999" strokeWidth="0.8" fill="none">
            <polyline id="Stroke-7" points="0.7,2.9 2.8,5 5.4,1"/>
          </g>
        </svg>
      </div>
    )
}

export default AcceptedClaimIcon

// Packages
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// Components
import Component from '../../../common/Component'
import ConditionBody from './ConditionBody'

class ConfirmedConditions extends Component {
  render () {
    let {
      conditions
    } = this.props

    let conditionContent = (
      <p className='no-conditions-available'>
        No conditions available.
      </p>
    )

    if (conditions.length) {
      conditionContent = conditions.map((condition, index) => {
        return (
          <ConditionBody
            key={index}
            count={index + 1}
            condition={condition}
            conditionState={condition.providerKnowledge || ''}
          />
        )
      })
    }

    return (
      <section className='review-group confirmed-conditions'>
        <header>
          <h3>Confirmed Conditions</h3>

          <div className='added-problem-list'>
            <FontAwesomeIcon icon={faInfoCircle} />
            <span>Will be added to problem list</span>
          </div>
        </header>

        {conditionContent}
      </section>
    )
  }
}

export default ConfirmedConditions

// Module imports
// Packages
import React from 'react'

// Components
import Component from '../../../common/Component'
import ConditionBody from './ConditionBody'

class ActiveConditions extends Component {
  render () {
    const {
      conditions
    } = this.props

    let conditionContent = (
      <p className='no-conditions-available'>
        No conditions available.
      </p>
    )

    if (conditions.length) {
      conditionContent = conditions.map((condition, index) => {
        return (
          <ConditionBody
            key={index}
            count={index + 1}
            condition={condition}
            conditionState={'DOCUMENTED'}
          />
        )
      })
    }

    return (
      <section className='review-group active-conditions'>
        <header>
          <h3>Active Conditions</h3>
        </header>

        {conditionContent}
      </section>
    )
  }
}

export default ActiveConditions

import React from 'react'

export default class Component extends React.Component {
  _bindMethods (methods) {
    if (typeof methods === 'string') {
      methods = [methods]
    }

    methods.forEach(method => this[method] = this[method].bind(this))
  }
}

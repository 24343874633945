// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'
import ReasonSelector from './ReasonSelector'

// Action Creators
import { updateCondition, saveCondition } from '../../../store/actions/conditions'
import { saveConditionAndSubmitForReview } from '../../../store/actions/signAndSubmit'

class ReasonStep extends Component {

    // Returns iff either:
    //   1. All conditions in conditionList are completed, OR
    //   2. condition is the final incomplete condition in conditionList
    allWillBeCompleted(condition, conditionList) {
        let completedCount = 0
        let isInList = false

        conditionList.forEach(currentCondition => {
            if (currentCondition.completed) {
                completedCount++
            }

            if (currentCondition.id === condition.id) {
                isInList = true
            }
        })

        // We know that condition must be the incomplete one in the list since there is only one which
        // is incomplete, and we know condition is in the list.
        return (
            (completedCount === (conditionList.length - 1) && isInList)
            ||
            (completedCount === conditionList.length)
        )
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////
    // Event handlers
    //////////////////////////////////////////////////////////////////////////////////////////////////

    // Called when a reason option is selected (NB: not necessarily when it changes)
    onOptionSelect(option) {
        const condition = this.props.condition

        if (option == null || option.id == null) {
            console.error('Cannot select reason option: option.id is null')
            return
        }

        // Did the option change? If so, recalculate state
        if (condition.reasonOption == null || condition.reasonOption.id !== option.id) {

            let updatedCondition = {
                ...condition,
                reasonOption: option
            }

            // Update Redux state
            this.props.updateCondition(updatedCondition)
        }
    }

    // Called when note field text is changed.
    // TODO: Debounce this function so that it doesn't updateCondition per keystroke
    onNoteTextChange(noteText) {
        const condition = this.props.condition
        noteText = noteText || ''

        let updatedCondition = {
            ...condition,
            note: noteText
        }

        // Update Redux state
        this.props.updateCondition(updatedCondition)
    }

    // Callback to tell MultiStepWorkflow the user completed this condition.
    onSaveClick() {
        if (this.props.condition.canSave) {
            // If this is the last condition left in the list, then save this AND submit for review
            if (this.allWillBeCompleted(this.props.condition, this.props.recommendedConditions.list)) {
                this.props.saveConditionAndSubmitForReview(this.props.condition)
            } else {
                this.props.saveCondition(this.props.condition)
            }
        }
    }

    // Ensure UI is up to date with new condition data
    updateUI(props) {
        // Hack: We just assume the index of the note steps later (Accepted note and rejected note)
        // We need to do a better job of organizing the note steps inside the Accepted/Rejected steps
        let notePrompt = null

        // Only show the note for Accepted conditions, or if required by a Rejected option
        let showNote = false

        if (props.condition.initialOption) {
            if (props.condition.initialOption.id === 'ACCEPTED') {
                notePrompt = props.condition.workflow.steps[3].description // Hack. See above.
                showNote = true
            } else if (props.condition.initialOption.id === 'REJECTED') {
                notePrompt = props.condition.workflow.steps[4].description // Hack. See above.

                // Do not show the note for Rejected steps unless note is required.
                if (props.condition.reasonOption && props.condition.reasonOption.requiresNote) {
                    showNote = true
                }
            }
        }

        return {
            notePrompt: notePrompt,
            showNote: showNote
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////
    // React Component lifecycle functions
    //////////////////////////////////////////////////////////////////////////////////////////////////

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState(this.updateUI(nextProps))
    }

    constructor(props) {
        super(props)

        this._bindMethods([
            'allWillBeCompleted',
            'onOptionSelect',
            'onNoteTextChange',
            'onSaveClick',
            'updateUI'
        ])

        this.state = {
            ...this.updateUI(props)
        }
    }

    render() {
        const {
            condition
        } = this.props

        const {
            notePrompt,
            showNote
        } = this.state

        if (condition == null || condition.currentStep == null) {
            return null
        }

        return (
            <form className='multi-step reason-step'>
                <header>
                    <h2>
                        {condition.currentStep.title}
                    </h2>
                </header>

                <ReasonSelector
                    condition={condition}
                    onOptionSelect={this.onOptionSelect}
                />

                {showNote &&
                <div className='reason-note'>
            <textarea
                placeholder={notePrompt}
                rows='4'
                value={condition.note || ''}
                onChange={e => {this.onNoteTextChange(e.target.value)}}
            />
                </div>
                }
            </form>
        )
    }
}

const mapStateToProps = (state) => ({
    recommendedConditions: state.conditions.recommended
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateCondition: updateCondition,
        saveCondition: saveCondition,
        saveConditionAndSubmitForReview: saveConditionAndSubmitForReview
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReasonStep)

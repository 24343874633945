// Packages
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

// Components
import Component from '../../common/Component'
import ErrorHandler from '../../common/System/Error'

class DocumentContainer extends Component {
  render() {
    const {
      evidenceDoc,
      error,
      fetching
    } = this.props

    let blobURL = evidenceDoc

    if (fetching) {
      return (
        <div className='pdf-viewer'>
          <div className='loading-pdf-container'>
            <FontAwesomeIcon icon={faSpinner} spin pulse />
          </div>
        </div>
      )
    } else if (error) {
      return (
        <ErrorHandler data={error} />
      )
    } else {
      return (
        <div className='pdf-viewer'>
          <object data={blobURL} type='application/pdf' width='100%' height='100%'>
            {/* This is fallback text - it will only display if the <object> fails to load. */}
            Your browser does not support PDFs.
          </object>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  evidenceDoc: state.evidenceDocument.data,
  error: state.evidenceDocument.error,
  fetching: state.evidenceDocument.fetching
})

export default connect(mapStateToProps)(DocumentContainer)

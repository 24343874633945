import actionTypes from '../actionTypes'
import initialState from '../initialState'
import { reduce, map } from 'lodash'

const reducer = (state = initialState.patientSummary, action) => {
  switch (action.type) {
    case actionTypes.PATIENT_SUMMARY_GET_DATA:
      switch (action.status) {
        case 'success':
          const { data } = action
          return {
            ...state,
            ...data,
            isLoading: false,
          }
        case 'error':
          return {
            ...state,
            isLoading: false,
          }
        default:
          return {
            ...state,
            isLoading: true,
          }
      }
    default:
      return state
  }
}

export const getSectionViews = (state, sections, innerTables, config) => {
  return reduce(sections, (acc, section, key) => {
    if (section === 'memberInformation') {
      return[...acc, {key: section, title: state[section].section, ...state[section] }]
    }
    return [
      ...acc,
      {
        key: section,
        title: state[section].section,
        tableData: innerTables[section] ? map(innerTables[section], (innerTable, innerTableName) => state[section][innerTable]) : [state[section]],
        config : innerTables[section] ? map(innerTables[section], (innerTable, innerTableName) => config[section][innerTable]) : config[section],
      },
    ]
  },[])
}

export default reducer

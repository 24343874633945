// Packages
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserMd,
  faQuestion,
  faClipboard
} from '@fortawesome/free-solid-svg-icons'


// Components
import Component from '../Component'

class NoPatientDataIcon extends Component {
  render() {
    return (
      <div className='no-patient-data-icon'>
        <div className='icon-container'>
          <FontAwesomeIcon icon={faUserMd} />
          <FontAwesomeIcon icon={faQuestion} />
          <FontAwesomeIcon icon={faClipboard} />
        </div>
      </div>
    )
  }
}

export default NoPatientDataIcon

// Packages
import React from 'react'
import { connect } from 'react-redux'

// Components
import Component from '../../common/Component'
import EvidenceGroup from './EvidenceGroup'
import ViewEvidenceDoc from './ViewEvidenceDoc'

class SupportingEvidence extends Component {

  // We need to sort the array of evidence to ensure the ICD-10 codes are grouped together.
  // Key is ICD-10 code string if exists, otherwise default to HCC. Value is an array of supporting evidence.
  sortEvidence(allEvidence, hcc) {
    let sortedEvidence = {}
    let evidence = null

    for (let i = 0; i < allEvidence.length; i++) {
      evidence = allEvidence[i]
      let code = evidence.code || ('HCC ' + hcc.id)

      if (sortedEvidence[code] == null) {
        sortedEvidence[code] = [evidence]
      } else {
        sortedEvidence[code].push(evidence)
      }
    }

    return sortedEvidence
  }

  getTitle(diagnosisName, columns) {
    if (columns === 1) {
      return 'Supporting Evidence'
    } else {
      return 'Supporting Evidence for ' + diagnosisName
    }
  }

  onViewDocument(date) {
    this.setState({
      evidenceDate: date,
      viewingDocument: true
    })
  }

  onDocumentClose() {
    this.setState({
      evidenceDate: null,
      viewingDocument: false
    })
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      evidenceDate: null,
      viewingDocument: false
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      evidenceDate: null,
      viewingDocument: false
    }

    this._bindMethods([
      'sortEvidence',
      'onViewDocument',
      'onDocumentClose',
      'getTitle'
    ])
  }

  render() {
    const {
      condition
    } = this.props

    const {
      columns
    } = this.props.env

    if (condition == null) {
      return (
        <section></section>
      )
    }

    if (this.state.viewingDocument) {
      return (
        <section className='supporting-evidence'>
          <ViewEvidenceDoc
            conditionId={condition.id}
            date={this.state.evidenceDate}
            onClose={this.onDocumentClose}
          />
        </section>
      )
    }

    // Display the evidence as grouped by HCC
    let allEvidence = condition.supportingEvidence
    if(condition.type !== 'clinicalinference') {
        // Display the evidence as grouped by ICD-10 code
        allEvidence = condition.recommendedDiagnosis.supportingEvidence
    }
    const sortedEvidence = this.sortEvidence(allEvidence, condition.hcc)
    const codes = Object.keys(sortedEvidence)
    let evidenceSections = []

    for (let i = 0; i < codes.length; i++) {
      evidenceSections.push(
        <EvidenceGroup
          key={i}
          evidences={sortedEvidence[codes[i]]}
          onViewDocument={this.onViewDocument}
          code={codes[i]}
        />
      )
    }


    let title = this.getTitle(condition.hcc.name, columns)
    if (condition.recommendedDiagnosis !== null) {
      title = this.getTitle(condition.recommendedDiagnosis.name, columns)
    }

    return (
      <section className='supporting-evidence'>
        <h1>{title}</h1>

        {evidenceSections}
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  env: state.env,
})

export default connect(mapStateToProps, null)(SupportingEvidence)

// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'
import MultiStepWorkflow from '../MultiStepWorkflow/MultiStepWorkflow'
import SingleStepWorkflow from '../SingleStepWorkflow/SingleStepWorkflow'

// Action Creators
import { openCondition, closeCondition } from '../../../store/actions/conditions'
import { submitForReview } from '../../../store/actions/signAndSubmit'

class ConditionList extends Component {

  // Called whenever the collapsable is clicked. Sets the active condition, and closes all others.
  toggleCollapsable(condition) {
    if (this.props.conditions.active != null && this.props.conditions.active.id === condition.id) {
      // If this condition is the current activeCondition, then close it
      this.props.closeCondition(condition)
    } else {
      // Open this condition and close all others
      this.props.openCondition(condition)
    }
  }

  // Returns true iff this condition is the active condition of this condition list.
  isConditionOpen(activeCondition, condition) {
    return (activeCondition != null && condition != null && activeCondition.id === condition.id)
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'toggleCollapsable',
      'isConditionOpen'
    ])
  }

  render() {
    const {
      conditions
    } = this.props

    return (
      <div>
        {conditions.list.map(condition => {
          const isOpen = this.isConditionOpen(conditions.active, condition)
          let conditionWorkflow = null

          // Two distinct workflows: Recommended condition, and Documented/Suspected conditions:
          if (condition.type === 'recommended') {
            // Recommended: Uses a simpler two-step workflow in the MultiStepWorkflow component
            conditionWorkflow = (
              <MultiStepWorkflow
                key={condition.id}
                isOpen={isOpen}
                toggleCollapsable={this.toggleCollapsable}
                condition={condition}
              />
            )
          } else {
            // Documented/Suspected: Uses the SingleStepWorkflow to manage the condition state
            conditionWorkflow = (
              <SingleStepWorkflow
                key={condition.id}
                isOpen={isOpen}
                toggleCollapsable={this.toggleCollapsable}
                condition={condition}
              />
            )
          }

          return (
            <div className='condition-group' key={'condition-workflow-' + condition.id}>
              {conditionWorkflow}
            </div>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  env: state.env
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openCondition: openCondition,
    closeCondition: closeCondition,
    submitForReview: submitForReview
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConditionList)

// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'

// Action Creators
import { updateCondition, saveCondition } from '../../../store/actions/conditions'

// Constants
import { KNOWLEDGE_TYPES } from '../../../constants/constants'

class KnowledgeStep extends Component {

  // Returns an object with the fields needed to maintain the condition's step state
  getNewStepFields(condition, option) {
    let newCurrentStep = condition.currentStep
    let newCurrentStepIndex = condition.currentStepIndex
    let newPrevStepIndex = condition.prevStepIndex
    let newNextStepIndex = condition.nextStepIndex
    let newPath = condition.path
    let checkStep = null
    let shouldSave = false

    // Change the step if a new option was selected
    if (option != null) {
      if (condition.currentStep.isSequential) {
        // The new currentStep should be the next step in the workflow
        checkStep = condition.workflow.steps[condition.currentStepIndex + 1] || null
      } else if (option.nextStep != null) {
        // The new currentStep should be whichever one is specified in this option
        checkStep = condition.workflow.steps[option.nextStep] || null
      } else {
        // Option is not null, but the nextStep is null, and it's not sequential - this is the end!
        shouldSave = true
      }

      if (checkStep != null) {
        // Go to the next step
        newCurrentStep = checkStep
        newCurrentStepIndex = condition.currentStepIndex + 1
        newPrevStepIndex = condition.currentStepIndex
        newNextStepIndex = null

        // Add the new current step to the path
        let pathCopy = condition.path.slice(0)
        pathCopy.push(newCurrentStepIndex)
        newPath = pathCopy
      }
    }

    return {
      currentStep: newCurrentStep,
      path: newPath,
      currentStepIndex: newCurrentStepIndex,
      prevStepIndex: newPrevStepIndex,
      nextStepIndex: newNextStepIndex,
      shouldSave: shouldSave
    }
  }

  // Called when a reason option is selected (NB: not necessarily when it changes)
  onOptionSelect(option) {
    const condition = this.props.condition

    if (option == null || option.id == null) {
      console.error('Cannot select knowledge option: option.id is null')
      return
    }

    let newNextStepIndex = condition.nextStepIndex
    let newPath = condition.path

    // Check if this is the a different option than before
    const previousOption = condition.optionSelected[condition.currentStep.name]
    if (previousOption != null && previousOption.description === option.description) {

      // Same option as before => deselect the option (does not change the step)
      option = null

      newNextStepIndex = null

      // Need to remove the next step from the path, if it's present
      if (condition.path[condition.path.length - 1] === condition.nextStepIndex) {
        newPath = condition.path.slice(0, condition.path.length - 2)
      }
    }

    let updatedCondition = {
      ...condition,
      optionSelected: {
        ...condition.optionSelected,
        [condition.currentStep.name]: option
      },
      nextStepIndex: newNextStepIndex,
      path: newPath
    }

    // Update the condition right away, so the selection shows up in the UI
    this.props.updateCondition(updatedCondition)

    // Determine next step, and if we're done with this condition
    let newStepFields = this.getNewStepFields(updatedCondition, option)
    let shouldSave = newStepFields.shouldSave
    delete newStepFields.shouldSave // Don't save this field to Redux

    updatedCondition = {
      ...updatedCondition,
      ...newStepFields
    }

    // If the step will change, add a small delay. Otherwise update immediately.
    if (updateCondition.currentStepIndex !== condition.currentStepIndex) {
      setTimeout(() => {
        this.props.updateCondition(updatedCondition)

        // If it was determined that the workflow is over, then save the condition too
        if (shouldSave) {
          this.props.saveCondition(updatedCondition)
        }
      }, 750)
    } else {
      this.props.updateCondition(updatedCondition)
    }
  }

  formatCircleButton(condition, option) {
    const currentlySelected = condition.optionSelected[condition.currentStep.name]
    let buttonClasses = 'circle-button '

    if (
      option.id === KNOWLEDGE_TYPES.KNOWN ||
      option.id === KNOWLEDGE_TYPES.UNKNOWN ||
      option.id === KNOWLEDGE_TYPES.UNCERTAIN
    ) {
      buttonClasses += option.id.toLowerCase()
    }

    // Set the active/selected option styling
    if (currentlySelected != null && currentlySelected.description === option.description) {
      buttonClasses += ' circle-button-active'
    }

    return (
      <div
        className={buttonClasses}
        key={option.id}
        onClick={() => this.onOptionSelect(option)}
      >
        {option.description}
      </div>
    )
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'getNewStepFields',
      'onOptionSelect',
      'formatCircleButton'
    ])
  }

  render() {
    const {
      condition
    } = this.props

    if (condition == null || condition.currentStep == null) {
      return null
    }

    let knowledgeOptions = []
    knowledgeOptions = condition.currentStep.options.map(option => {
      return this.formatCircleButton(condition, option)
    })

    return (
      <form className='single-step single-step-knowledge'>
        <header>
          <h2>
            {condition.currentStep.title}
          </h2>
        </header>

        <div className='knowledge-selector'>
          {knowledgeOptions}
        </div>
      </form>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateCondition: updateCondition,
    saveCondition: saveCondition
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(KnowledgeStep)

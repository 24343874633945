// Packages
import { every, find } from 'lodash'

////////////////////////////////////////////////////////////////////////////////////////////////////
// MULTI-STEP CONDITIONS - HELPER FUNCTIONS
//
// Singleton namespace for similarly named functions between single-step and multi-step workflows.
////////////////////////////////////////////////////////////////////////////////////////////////////

export const multiStep = {
  // Attach the necessary UI data to each condition object for the MultiStepWorkflow conditions.
  setConditionUI: function(conditions) {
    return conditions.map(condition => {
      let completed = false
      let statuses = []
      let reasonStep = null
      let reasonOption = null

      let initialOption = find(condition.workflow.steps[0].options, option => {
        return option.id === condition.providerKnowledge
      })

      if (initialOption != null) {
        if (initialOption.id === 'ACCEPTED_DOCUMENT_SEPARATELY') {
          completed = true
        } else {
          reasonStep = condition.workflow.steps[parseInt(initialOption.nextStep, 10)]

          // Try to determine the condition status (reasonOption) based on the text in statuses
          if (condition.statuses && condition.statuses.length) {
            reasonOption = find(reasonStep.options, option => {
              return option.id === condition.statuses[0]
            })
          }

          // If that didn't work, then we can try to determine reasonOption based on the note text
          if (reasonOption == null && condition.note && condition.note.length) {
            if (initialOption.id === 'ACCEPTED') {
              // No reasonOption is required if a note was entered (for the Accepted reason step)
              completed = true
            } else if (initialOption.id === 'REJECTED') {
              // Rejected condition status is saved in the note. See if it matches any of the options
              reasonOption = find(reasonStep.options, option => {
                return option.description === condition.note
              })

              // If it doesn't, just set it to the 'Other' option
              if (reasonOption == null) {
                reasonOption = reasonStep.options[reasonStep.options.length - 1]
              }
            }
          }
        }

        // If reasonOption was found at some point, then mark it as completed
        if (reasonOption != null) {
          completed = true
          statuses = [`${reasonOption.id}`] // We need to use the id, not the description text
        }
      }

      return {
        ...condition,
        type: condition.type.toLowerCase(),
        initialOption: initialOption,
        reasonStep: reasonStep,
        reasonOption: reasonOption,
        completed: completed,
        statuses: statuses
      }
    })
  },

  // Returns true iff condition can be saved, based on the reason selected and the note text.
  canSaveCondition: function(condition) {
    if (condition.reasonStep == null) {
      return false
    }

    // Need to make sure the selected option is one of the visible options (not a different step's)
    let optionIds = condition.reasonStep.options.map(option => {
      return option.id
    })

    let isReasonOptionValid = condition.reasonOption && optionIds.indexOf(condition.reasonOption.id) !== -1

    // It's way easier to understand the save logic if we split these up.
    if (condition.providerKnowledge === 'ACCEPTED') {
      // Save is active if either:
      //   1. A valid reason IS selected
      //   2. A note IS NOT empty
      return (
        isReasonOptionValid
        ||
        (condition.note != null && condition.note.length)
      )
    } else if (condition.providerKnowledge === 'REJECTED') {
      // Save is active if either:
      //   1. A reason is selected and a note IS NOT required
      //   2. A reason is selected and a note IS required, and that note IS NOT empty.
      return (
        isReasonOptionValid
        &&
        (
          (!condition.reasonOption.requiresNote)
          ||
          (condition.note != null && condition.note.length)
        )
      )
    }
  },

  // Returns true iff the SubmitForReview action button can be clicked. Depends on the allRequired
  // flag and the condition.completed/condition.canSave values.
  canSubmitForReview: function(state, conditions) {
    if (state.allRequired) {
      // If an incomplete condition is found, then return false
      let allCompleted = false

      // A note about why canSave is used here: This field indicates that the condition is not yet
      // completed, but WOULD be completed if the user decided to. We allow canSubmitForReview to be
      // true here because when the submitForReview button is clicked, it will save the active
      // condition and add it to the submission.

      every(conditions, condition => {
        if (condition.completed || condition.canSave) {
          allCompleted = true
          return true // check the next condition.completed
        } else {
          allCompleted = false
          return false // found an incomplete condition, break loop
        }
      })

      return allCompleted
    } else {
      // If one completed condition is found, then return true
      let someCompleted = false

      every(conditions, condition => {
        if (!condition.completed && !condition.canSave) {
          return true // try again
        } else {
          someCompleted = true
          return false // found it, break loop
        }
      })

      return someCompleted
    }
  }
}

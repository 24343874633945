// Packages
import { every } from 'lodash'

// Helper functions
import { singleStep } from './singleStep'
import { multiStep } from './multiStep'

// Constants
import {
  WORKFLOW_TYPES
} from '../../../constants/constants'

////////////////////////////////////////////////////////////////////////////////////////////////////
// Routing functions to Condition Helper functions
////////////////////////////////////////////////////////////////////////////////////////////////////

export const Helper = {
  // Expose these helpers to the condition reducer
  singleStep: singleStep,
  multiStep: multiStep,

  setConditionUI: function(conditions) {
    if (conditions[0] && conditions[0].type) {
      // Ensure lowercase type
      let type = conditions[0].type.toLowerCase()
      if (conditions[0].type === "CLINICAL_INFERENCE") {
        type = "clinicalinference"
      }

      // Split based on the type of condition workflow this condition uses
      if (WORKFLOW_TYPES.SINGLE_STEP.indexOf(type) !== -1) {
        return singleStep.setConditionUI(conditions)
      } else if (WORKFLOW_TYPES.MULTI_STEP.indexOf(type) !== -1) {
        return multiStep.setConditionUI(conditions)
      } else {
        console.error('Not sure what type of workflow this is! Type: ' + type)
        return false
      }
    } else {
      return conditions
    }
  },

  // Routing function to determine if a condition is allowed to be completed
  canSaveCondition: function(condition) {
    if (condition == null) {
      return false
    }

    // Ensure lowercase type
    let type = condition.type.toLowerCase()
    if (condition.type === "CLINICAL_INFERENCE") {
      type = "clinicalinference"
    }
    // Split based on the type of condition workflow this condition uses
    if (WORKFLOW_TYPES.SINGLE_STEP.indexOf(type) !== -1) {
      return singleStep.canSaveCondition(condition, type === "clinicalinference")
    } else if (WORKFLOW_TYPES.MULTI_STEP.indexOf(type) !== -1) {
      return multiStep.canSaveCondition(condition)
    } else {
      console.error('Not sure what type of workflow this is! Type: ' + type)
      return false
    }
  },

  // Returns true iff the SubmitForReview action button can be clicked. Depends on the allRequired
  // flag and the condition.completed/condition.canSave values.
  canSubmitForReview: function(state, conditions) {
    if (state.recommendedWorkflow) {
      return multiStep.canSubmitForReview(state, conditions)
    } else {
      return singleStep.canSubmitForReview(state, conditions)
    }
  },

  // Returns the first condition in the list which is not marked as completed
  getNextUncompletedCondition: function(conditions) {
    let activeCondition = null

    every(conditions, condition => {
      if (condition.completed) {
        return true // try again
      } else {
        activeCondition = condition
        return false // found it, break loop
      }
    })

    return activeCondition
  },

  // Reverts the condition data used by the UI to the state of the activeCondition (which is a cached
  // version of the condition data when it was opened). Effectively undoes unsaved user choices.
  // Returns an array of conditions containing the rolled-back version of the former active condition.
  revertConditionData: function(activeCondition, conditionArray) {
    if (activeCondition == null) {
      return conditionArray
    }

    let revertedArray = []
    let currentCondition = null

    for (let i = 0; i < conditionArray.length; i++) {
      currentCondition = conditionArray[i]

      // Find the active condition and replace its data with the cached data in activeCondition
      if (currentCondition.id === activeCondition.id) {
        revertedArray.push({
          ...activeCondition
        })
      } else {
        revertedArray.push({
          ...currentCondition
        })
      }
    }

    return revertedArray
  },

  // Returns true iff all conditions are completed across all condition lists
  checkAllCompleted: function(state, localAllCompleted, type) {
    // The global allCompleted flag should be true iff
    //   1. localAllCompleted is true
    //   2. All other condition groups are marked as allCompleted
    if (localAllCompleted) {
      let allOthersCompleted = true

      // Only look in the condition groups, not the other state.condition data
      const conditionGroups = {
        documented: state['documented'],
        recommended: state['recommended'],
        suspected: state['suspected'],
        clinicalinference: state['clinicalinference'],
      }

      every(conditionGroups, (conditionGroup, key)  => {
        // Don't check the local condition group, since we already have localAllCompleted
        if (key !== type) {
          if (conditionGroup.allCompleted) {
            return true // try the next condition group
          } else {
            allOthersCompleted = false
            return false // found an incomplete group
          }
        } else {
          return true // This is the local condition group - try the next one!
        }
      })

      return allOthersCompleted
    }

    return false
  }
}

// Packages
import React from 'react'
import Component from '../../common/Component'

export default class ReasonSelector extends Component {
  render() {
    const {
      condition,
      onOptionSelect
    } = this.props

    if (condition == null || condition.id == null || condition.providerKnowledge == null || condition.reasonStep == null) {
      return null
    }

    return (
      <div className={'radio-options-container ' + condition.providerKnowledge.toLowerCase()}>
        {condition.reasonStep.options.map((option, index) => {
          const optionId = condition.id + '_' + condition.providerKnowledge + '-' + index

          let radioSquareClasses = 'radio-square'
          if (condition.reasonOption != null && condition.reasonOption.id === option.id) {
            radioSquareClasses += ' selected'
          }

          return (
            <div key={optionId} className='radio-option' onClick={() => { onOptionSelect(option) }}>
              <div className={radioSquareClasses} />

              <div className='radio-option-desc'>
                {option.description}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

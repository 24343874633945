// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { debounce } from 'lodash'

// Stylesheet
import './styles/app.scss'

// Components
import Component from './component/common/Component'
import Loading from './component/common/System/Loading'
import ErrorHandler from './component/common/System/Error'
import NotificationHandler from './component/common/System/Notification'
import HccContainer from './component/features/hcc/HccContainer'
import SecondaryColumn from './component/features/hcc/SecondaryColumn'
import PatientSummaryContainer from './component/features/PatientSummary/PatientSummaryContainer'

// Action Creators
import { setToken, loginEncryptedGet }  from './store/actions/authentication'
import { setColumns } from './store/actions/env'

// Constants
import {
  NOTIFICATION_CATEGORIES,
  SINGLE_COLUMN_MAX_WIDTH
} from './constants/constants'

class App extends Component {
  loginUser() {
    const queryParams = {}
    const path = window.location.pathname
    window.location.search.replace(/^\?/, '').split('&').forEach(pair => {
      let [key, value] = pair.split('=')

      queryParams[key] = value
    })

    if (path && path === '/sso') {
      this.props.loginEncryptedGet(
        window.location.search
      )
    } else {  
      this.props.setToken(
        window.location.search.split('=')[1]
      )
    }

    //timeout function here after 29.5 minutes
    setTimeout(() => {
      this.setState ({
        isTimeoutError: true
      }) 
      return 
    },
    1770000)
  }

  // Sets up a listener on window resizing. Returns a function to be called when you want to
  // deregister the listener (when this component unmounts).
  registerResizeListener() {
    const debounceDelay = 150
    return window.addEventListener(
      'resize',
      debounce(this.setColumns, debounceDelay),
      false
    )
  }

  // Sets the number of columns to use, based on the viewport width
  setColumns() {
    if (window.innerWidth <= SINGLE_COLUMN_MAX_WIDTH) {
      this.props.setColumns(1)
    } else {
      this.props.setColumns(2)
    }
  }

  componentDidMount () {
    this.loginUser()
    this.setColumns()
    this.setState.unregisterResizeListener = this.registerResizeListener()
  }

  componentWillUnmount() {
    this.state.unregisterResizeListener()
  }

  constructor(props) {
    super(props)
    this.state = {
      isTimeoutError: false
    }
    this._bindMethods([
      'loginUser',
      'registerResizeListener',
      'setColumns'
    ])
  }

  render () {
    const {
      loggingIn,
      hasHCC,
      hasPatientSummary
    } = this.props.authentication

    const {
      fetchingHCC,
      notification
    } = this.props

    const {
      columns
    } = this.props.env

    let classNames = 'convergence'
    let columnClass = 'one-column'

    if (columns && columns === 2) {
      columnClass = 'two-column'
    }

    classNames += ' ' + columnClass

    // If nothing has been done, show the loading screen
    if (loggingIn || fetchingHCC) {
      return (
        <Loading />
      )
    } else if (this.state.isTimeoutError) {
        let error = {
          message: "Your session has reached the limit of 30 minutes",
          code: "isTimeoutError"
        }
        return (
          <ErrorHandler data={error}/>
        )
      }

    // If an error has been added to the global state, then we need to display it
    else if (notification != null && notification.category != null) {
      if (notification.category === NOTIFICATION_CATEGORIES.ERROR) {
        return (
          <ErrorHandler data={notification.data} />
        )
      } else {
        return (
          <NotificationHandler notification={notification} />
        )
      }
    }

    if (hasHCC) {
      if (columns === 2) {
        return (
          <div className={classNames}>
            <HccContainer />
            <div className='grid-divider'></div>
            <SecondaryColumn />
          </div>
        )
      } else {
        return (
          <div className={classNames}>
            <HccContainer />
          </div>
        )
      }
    }

    if (hasPatientSummary) {
      return (
        <div className='convergence one-column'>
          <PatientSummaryContainer />
        </div>
      )
    }

    return null
  }
}

const mapStateToProps = ( state ) => ({
  authentication: state.authentication,
  env: state.env,
  fetchingHCC: state.conditions.fetching,
  notification: state.notification,
  isTimeoutError: state.isTimeoutError
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loginEncryptedGet: loginEncryptedGet,
    setToken: setToken,
    setColumns: setColumns
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

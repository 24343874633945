// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'
import DocumentContainer from './DocumentContainer'
import ClinicalNoteIcon from '../../common/icons/ClinicalNoteIcon'
import CloseModalButton from '../../common/Button/CloseModalButton'

// Action Creators
import getEvidenceDocument from '../../../store/actions/getEvidenceDocument'

class ViewEvidenceDoc extends Component {
  componentDidMount() {
    this.props.getEvidenceDocument(this.props.conditionId)
  }

  render() {
    const {
      date,
      onClose
    } = this.props

    const evidenceName = 'Clinical Note'

    return (
      <div className='evidence-doc-container'>
        <div className='view-evidence-doc'>
          <div className='doc-header-row'>
            <div className='doc-header'>
              <div className='evidence-icon'>
                <ClinicalNoteIcon />
              </div>
              <div>
                <div>
                  {evidenceName}
                </div>
                <div>
                  {date}
                </div>
              </div>
            </div>

            <CloseModalButton onClick={onClose}/>
          </div>

          <DocumentContainer />
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getEvidenceDocument: getEvidenceDocument
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(ViewEvidenceDoc)

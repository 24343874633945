// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'

// Action Creators
import { updateCondition, saveCondition } from '../../../store/actions/conditions'
import { saveConditionAndSubmitForReview } from '../../../store/actions/signAndSubmit'

class InitialStep extends Component {
  // Called when an initial option is selected (NB: not necessarily when it changes)
  onOptionSelect(option) {
    const condition = this.props.condition
    const steps = condition.workflow.steps
    let activeBtnId = null
    let activeBtnText = null
    let reasonStep = null
    let documentSeparatelyActive = false
    let completed = false

    if (option == null || option.id == null) {
      console.error('Cannot select initial option: option.id is null')
      return
    }

    // Did the option change? If so, recalculate state
    if (condition.initialOption == null || condition.initialOption.id !== option.id) {
      reasonStep = steps[option.nextStep]

      if (option.id === 'ACCEPTED' || option.id === 'ACCEPTED_DOCUMENT_SEPARATELY') {
        activeBtnId = 'ACCEPTED'
        activeBtnText = 'Accepted'

        if (option.id === 'ACCEPTED_DOCUMENT_SEPARATELY') {
          documentSeparatelyActive = true
          reasonStep = null
          completed = true
        }
      } else if (option.id === 'REJECTED') {
        activeBtnId = 'REJECTED'
        activeBtnText = 'Rejected'
      }

      let updatedCondition = {
        ...condition,
        providerKnowledge: option.id,
        initialOption: option,
        reasonStep: reasonStep,
        note: null, // Always reset note when changing the initial option
        completed: completed
      }

      // Update Redux state
      this.props.updateCondition(updatedCondition)

      // Let this condition stay open for a bit so the user knows Document Separately was selected.
      if (completed) {
        setTimeout(() => {
          if (this.props.recommendedConditions.allCompleted) {
            this.props.saveConditionAndSubmitForReview(updatedCondition)
          } else {
            this.props.saveCondition(updatedCondition)
          }
        }, 1000)
      }

      // Local state, not tracked by Redux
      this.setState({
        activeBtnId: activeBtnId,
        activeBtnText: activeBtnText,
        documentSeparatelyActive: documentSeparatelyActive
      })
    }
  }

  // When Redux state is changed, we need to update local state to match
  updateUI(props) {
    if (props.condition == null) {
      return
    }

    let activeBtnId = null
    let activeBtnText = null
    let documentSeparatelyActive = false

    if (props.condition.providerKnowledge === 'ACCEPTED' ||
      props.condition.providerKnowledge === 'ACCEPTED_DOCUMENT_SEPARATELY') {

      activeBtnId = 'ACCEPTED'
      activeBtnText = 'Accepted'

      if (props.condition.providerKnowledge === 'ACCEPTED_DOCUMENT_SEPARATELY') {
        documentSeparatelyActive = true
      }
    } else if (props.condition.providerKnowledge === 'REJECTED') {
      activeBtnId = 'REJECTED'
      activeBtnText = 'Rejected'
    }

    // Local state, not tracked by Redux
    return {
      activeBtnId: activeBtnId,
      activeBtnText: activeBtnText,
      documentSeparatelyActive: documentSeparatelyActive
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.updateUI(nextProps))
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'onOptionSelect',
      'updateUI'
    ])

    // Setup a shorthand for the option objects
    let acceptOption = null
    let rejectOption = null
    let documentSeparatelyOption = null
    props.condition.workflow.steps[0].options.forEach(option => {
      if (option.id === 'ACCEPTED') {
        acceptOption = option
      } else if (option.id === 'REJECTED') {
        rejectOption = option
      } else if (option.id === 'ACCEPTED_DOCUMENT_SEPARATELY') {
        documentSeparatelyOption = option
      }
    })

    this.state = {
      ...this.updateUI(props),
      acceptOption: acceptOption,
      rejectOption: rejectOption,
      documentSeparatelyOption: documentSeparatelyOption
    }
  }

  render() {
    const {
      condition
    } = this.props

    const {
      activeBtnId,
      activeBtnText,
      documentSeparatelyActive,
      acceptOption,
      rejectOption,
      documentSeparatelyOption
    } = this.state

    return (
      <section className='multi-step initial-step'>
        <header>
          <h1>
            {condition.recommendedDiagnosis.name}
          </h1>
        </header>
        <div className='option-buttons'>
          {[acceptOption, rejectOption].map((option) => {
            return (
              <button
                key={option.id}
                ref={option.id}
                className={(activeBtnId === option.id || !activeBtnId ? 'active' : '') + ' ' + option.description.toLowerCase()}
                onClick={() => {this.onOptionSelect(option)}}
                type='button'>
                  {activeBtnId !== option.id ? option.description : activeBtnText}
              </button>
            )
          })}
          <div className='button-spacer'></div>
        </div>

        {documentSeparatelyOption &&
          <div className='link-option'>
            <span
              className={documentSeparatelyActive ? 'active' : ''}
              onClick={() => {this.onOptionSelect(documentSeparatelyOption)}}>

              {documentSeparatelyOption.description}
            </span>
          </div>
        }
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  recommendedConditions: state.conditions.recommended
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateCondition: updateCondition,
    saveCondition: saveCondition,
    saveConditionAndSubmitForReview: saveConditionAndSubmitForReview
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialStep)

// Packages
import React from 'react'

// Components
import Component from '../Component'
import Dialog from './Dialog'

class ReviewModal extends Component {
  render() {
    let {
      children,
      isOpen,
      onClose,
    } = this.props

    return (
      <Dialog className='review-modal' isModal={true} isOpen={isOpen} onClose={onClose}>
        {children}
      </Dialog>
    )
  }
}

export default ReviewModal

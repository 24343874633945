import actionTypes from '../actionTypes'
import initialState from '../initialState'

const reducer = (state = initialState.notification, action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION: {
      return {
        ...state,
        category: action.category,
        data: action.data,
        correlationId: action.correlationId || null
      }
    }

    // Remove the notification when a retried action is successful
    case actionTypes.SIGN_AND_SUBMIT: {
      switch (action.status) {
        case 'success': {
          return {
            ...state,
            category: null,
            data: null,
            correlationId: null
          }
        }

        default: {
          return {
            ...state,
          }
        }
      }
    }

    default: {
      return {
        ...state
      }
    }
  }
}

export default reducer

import React from 'react'
import TableProto from './TableProto'
import { map } from 'lodash'

import 'react-table/react-table.css'

class PatientSummaryTable extends TableProto {
  /***************************************************************************\
    Public Methods
  \***************************************************************************/

  render () {
    const { data } = this.props

    return (
      <div>
        {map(data.tableData, (tableData, index1) => {
          return (
            <table key={index1} className='table'>
              <thead>
                <tr>
                  {map(tableData.columns, (td, index2) => {
                    return (
                      <th key={index2}>{td.header}</th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {map(tableData.rows, (row, index3) => {
                  return (
                    <tr key={index3} >
                      {map(row, (r, index4) => {
                        if (typeof r === 'object') {
                          return <td key={index4}>{r.value || 'N/A'}</td>
                        }
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )
        })}
      </div>
    )
  }
}

export default PatientSummaryTable

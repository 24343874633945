import actionTypes from '../actionTypes'
import API_ROOT from '../apiService'
import store from '../store'

import { addError } from '../helpers/notificationHelper'

export const getPatientSummary = () => {
  return dispatch => {
    dispatch({ type: actionTypes.PATIENT_SUMMARY_GET_DATA })

    API_ROOT.request({
      url: '/patient-summary',
      method: 'get',
      headers: {
        Authorization: `Bearer ${store.getState().authentication.token}`
      }
    }).then(response => {
      const { data } = response.data
      dispatch({
        type: actionTypes.PATIENT_SUMMARY_GET_DATA,
        status: 'success',
        data
      })
    }).catch(error => {
      dispatch({
        type: actionTypes.PATIENT_SUMMARY_GET_DATA,
        status: 'error',
      })

      addError(dispatch, error)
    })
  }
}

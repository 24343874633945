// Packages
import React from 'react'
import { capitalize } from 'lodash'

// Components
import Component from '../../../common/Component'
import ConditionFields from './ConditionFields'

class ConditionBody extends Component {

  // Returns an object whose keys are [status, diagnosis, note], and whose values are Booleans that
  // indicate whether a certain field can be rendered (NB: Does not imply that it WILL be rendered).
  getAllowedFields(conditionState) {
    let allowStatus = true
    let allowDiagnosis = true
    let allowNote = true

    if (conditionState === 'DOCUMENTED') {
      allowDiagnosis = false
    } else if (conditionState === 'KNOWN') {
      // All are allowed for KNOWN conditions
    } else if (conditionState === 'UNKNOWN') {
      allowStatus = false
    } else if (conditionState === 'UNCERTAIN') {
      allowStatus = false
      allowDiagnosis = false
      allowNote = false
    }

    return {
      status: allowStatus,
      diagnosis: allowDiagnosis,
      note: allowNote
    }
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'getAllowedFields'
    ])
  }

  render () {
    let {
      condition,
      count,
      conditionState
    } = this.props

    const noConditionData = (
      <div>
        <span className='field-name'>
          No condition data available
        </span>
      </div>
    )

    if (condition == null || conditionState == null) {
      return noConditionData
    }

    const allowField = this.getAllowedFields(conditionState)
    let conditionClass = conditionState.toLowerCase()

    return (
      <div className={'condition-body ' + conditionClass}>
        <header>
          <div className={'circle ' + conditionClass}>
            {count}
          </div>

          <div>{condition.hcc.name}</div>

          {(conditionClass !== 'documented') &&
            <div className='knowledge-badge-container'>
              <span className={'knowledge-badge ' + conditionClass} >
                {capitalize(conditionClass)}
              </span>
            </div>
          }
        </header>

        <div className='fields'>
          {allowField.status && condition.statuses != null &&
            <ConditionFields type='Status' value={condition.statuses[0]} />
          }

          {allowField.diagnosis && allowField.diagnosis.description &&
            <ConditionFields type='Diagnosis' value={condition.diagnosis.description} />
          }

          {allowField.note &&
            <ConditionFields type='Note' value={condition.note} />
          }
        </div>
      </div>
    )
  }
}

export default ConditionBody

// Packages
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

// Components
import Component from '../Component'

export default class AsyncButton extends Component {
  render () {
    let {
      classNames,
      children
    } = this.props

    const {
      pending,
      disabled,
      onClick
    } = this.props

    if (classNames != null && classNames.length) {
      classNames += ' button-async'      
    } else {
      classNames = 'button-async'
    }

    if (disabled) {
      classNames += ' disabled'
    }

    if (pending) {
      children = <FontAwesomeIcon icon={faSpinner} spin pulse />
    }

    return (
      <button
        type='button'
        className={classNames}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    )
  }
}

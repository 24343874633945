import { includes } from 'lodash'
import actionTypes from '../actionTypes'
import initialState from '../initialState'

import { pruneSingleStepCondition, pruneMultiStepCondition } from '../actions/conditions'

// Given an array of condition data, it adds or updates the condition in the submission array.
// Returns the new submission array.
function addConditionsToSubmission(submissions, newConditions) {
  let newConditionIds = newConditions.map(condition => {
    return condition.id
  })

  // Keep the conditions in submission which are not being updated
  const oldItems = submissions.filter(submissionItem => {
    return (submissionItem && !includes(newConditionIds, submissionItem.id))
  })

  // Then add in the new conditions and return the whole thing
  return [...oldItems, ...newConditions]
}

const reducer = (state = initialState.submission, action) => {
  switch (action.type) {
    case actionTypes.SAVE_CONDITION: {
      return addConditionsToSubmission(state, [action.limitedCondition])
    }

    // Add each completed condition to the submission array
    case actionTypes.HCC_DATA_GET: {
      if (action.recommended == null) {
        return state
      }

      let completedConditions = []

      action.recommended.forEach(condition => {
        if (condition.completed) {
          completedConditions.push(pruneMultiStepCondition(condition))
        }
      })

      action.documented.forEach(condition => {
        if (condition.completed) {
          completedConditions.push(pruneSingleStepCondition(condition))
        }
      })

      action.suspected.forEach(condition => {
        if (condition.completed) {
          completedConditions.push(pruneSingleStepCondition(condition))
        }
      })

      return addConditionsToSubmission(state, completedConditions)
    }

    default:
      return state
  }
}

export default reducer
import React from 'react'
import Component from '../../common/Component'
import EvidenceHeader from './EvidenceHeader'

class EvidencePlain extends Component {
  render() {
    const {
      evidence
    } = this.props

    return (
      <section className='evidence evidence-plain'>
        <EvidenceHeader
          evidenceName={evidence.dataSource}
          docType={evidence.docType}
          dateOfService={evidence.dateOfService}
          author={evidence.author}
          location={evidence.location} />
      </section>
    )
  }
}

export default EvidencePlain

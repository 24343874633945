import React from 'react'
import Component from '../../common/Component'
import EvidenceWithDoc from './EvidenceWithDoc'
import EvidencePlain from './EvidencePlain'

class EvidenceGroup extends Component {
  render() {
    const {
      evidences,
      onViewDocument,
      code
    } = this.props

    if (evidences == null || evidences.length === 0) {
      return (
        <section></section>
      )
    }

    // Group ICD-10 code and name
    const codeName = evidences[0].codeName

    let hasDisplayText = false
    let isClinicalNote = false

    // TODO: sort array with hasDoc evidences first
    // Two types of evidence to display: Note, and NonNote
    const evidenceSections = evidences.map((evidence, index) => {
      hasDisplayText = evidence.displayText != null && evidence.displayText.length > 0
      isClinicalNote = evidence.dataSource && evidence.dataSource === 'CLINICAL_RECORD'

      if (hasDisplayText && isClinicalNote) {
        return (
          <EvidenceWithDoc
            key={index}
            evidence={evidence}
            onViewDocument={onViewDocument} />
        )
      } else {
        return (
          <EvidencePlain
            key={index}
            evidence={evidence} />
        )
      }
    })

    return (
      <section className="evidence-group">
        <h2>{code} - {codeName}</h2>

        {evidenceSections}
      </section>
    )
  }
}

export default EvidenceGroup

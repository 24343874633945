// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'

// Action Creators
import { updateCondition } from '../../../store/actions/conditions'

class DiagnosisStep extends Component {

  // Save this search text to the Redux condition state. updateCondition will deal with filtering
  // the options array based on search text.
  onSearchTextChange(text) {
    this.props.updateCondition({
      ...this.props.condition,
      currentStep: {
        ...this.props.condition.currentStep,
        searchText: text
      }
    })
  }

  // Returns an object with the fields needed to maintain the condition's step state
  getNewStepFields(condition, option) {
    let newCurrentStep = condition.currentStep
    let newCurrentStepIndex = condition.currentStepIndex
    let newPrevStepIndex = condition.prevStepIndex
    let newNextStepIndex = condition.nextStepIndex
    let newPath = condition.path
    let checkStep = null
    let shouldSave = false

    // Change the step if a new option was selected
    if (option != null) {
      if (condition.currentStep.isSequential) {
        // The new currentStep should be the next step in the workflow
        checkStep = condition.workflow.steps[parseInt(condition.currentStepIndex, 10) + 1] || null
      } else if (option.nextStep != null) {
        // The new currentStep should be whichever one is specified in this option
        checkStep = condition.workflow.steps[option.nextStep] || null
      } else {
        // Option is not null, but the nextStep is null, and it's not sequential - this is the end!
        shouldSave = true
      }

      if (checkStep != null) {
        // Go to the next step
        newCurrentStep = checkStep
        newCurrentStepIndex = checkStep.id
        newPrevStepIndex = condition.currentStepIndex.valueOf()
        newNextStepIndex = null

        // Add the new current step to the path
        let pathCopy = condition.path.slice(0)
        pathCopy.push(newCurrentStepIndex)
        newPath = pathCopy
      }
    }

    return {
      currentStep: newCurrentStep,
      path: newPath,
      currentStepIndex: newCurrentStepIndex,
      prevStepIndex: newPrevStepIndex,
      nextStepIndex: newNextStepIndex,
      shouldSave: shouldSave
    }
  }

  // Called when a reason option is selected (NB: not necessarily when it changes)
  onOptionSelect(option) {
    const condition = this.props.condition

    if (option == null || option.id == null) {
      console.error('Cannot select knowledge option: option.id is null')
      return
    }

    let newNextStepIndex = condition.nextStepIndex
    let newPath = condition.path

    // Check if this is the a different option than before
    const previousOption = condition.optionSelected[condition.currentStep.name]
    if (previousOption != null && previousOption.description === option.description) {

      // Same option as before => deselect the option (does not change the step)
      option = null

      newNextStepIndex = null

      // Need to remove the next step from the path, if it's present
      if (condition.path[condition.path.length - 1] === condition.nextStepIndex) {
        newPath = condition.path.slice(0, condition.path.length - 2)
      }
    }

    let updatedCondition = {
      ...condition,
      diagnosis: {
        id: option.id,
        idType: 'ICD-10-CM',
        name: option.description.split(' - ')[1],
        icd10Code: option.description.split(' - ')[0]
      },
      optionSelected: {
        ...condition.optionSelected,
        [condition.currentStep.name]: option
      },
      nextStepIndex: newNextStepIndex,
      path: newPath
    }

    // Update the condition right away, so the selection shows up in the UI
    this.props.updateCondition(updatedCondition)

    // Determine next step, and if we're done with this condition
    let newStepFields = this.getNewStepFields(updatedCondition, option)
    let shouldSave = newStepFields.shouldSave
    delete newStepFields.shouldSave // Don't save this field to Redux

    updatedCondition = {
      ...updatedCondition,
      ...newStepFields
    }

    // If the step will change, add a small delay. Otherwise update immediately.
    if (updateCondition.currentStepIndex !== condition.currentStepIndex) {
      setTimeout(() => {
        this.props.updateCondition(updatedCondition)

        // If it was determined that the workflow is over, then save the condition too
        if (shouldSave) {
          this.props.saveCondition(updatedCondition)
        }
      }, 750)
    } else {
      this.props.updateCondition(updatedCondition)
    }
  }

  formatCodeOption(condition, option) {
    if (option.description == null || option.id == null || option.value == null) {
      return <div></div>
    }

    const currentlySelected = condition.optionSelected[condition.currentStep.name]
    let optionClasses = 'code-option '

    // Set the active/selected option styling
    if (currentlySelected != null && currentlySelected.id === option.id) {
      optionClasses += ' code-option-active'
    }

    // Split the code from the description, and keep each part as its own div inside code-option
    // Example option.description: 'S14115A - C5-C7 level with complete lesion of spinal cord'
    //const re = /(\w+) [\-\–] /g // hypen or emdash
    const re = /(\w+.\w+)\s-\s\b([\s\S]+)/g
    let code = re.exec(option.description)[1]

    // Return the properly formatted option to display
    return (
      <div
        className={optionClasses}
        key={option.id}
        onClick={() => this.onOptionSelect(option)}
      >
        {code &&
          <div className='code-number'>
            {code}
          </div>
        }

        <div className='code-description'>
          {option.value}
        </div>
      </div>
    )
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'onSearchTextChange',
      'getNewStepFields',
      'onOptionSelect',
      'formatCodeOption'
    ])
  }

  render() {
    const {
      condition
    } = this.props

    if (condition == null || condition.currentStep == null) {
      return null
    }

    let diagnosisOptions = []
    condition.currentStep.options.forEach(option => {
      if (option.isVisible) {
        diagnosisOptions.push(this.formatCodeOption(condition, option))
      }
    })

    return (
      <form className='single-step single-step-diagnosis'>
        <header>
          <h2>
            {condition.currentStep.title}
          </h2>
        </header>

        <div className='diagnosis-search'>
          <textarea
            rows='1'
            autoFocus
            placeholder='Search by diagnosis code or description.'
            value={condition.currentStep.searchText || ''}
            onChange={(e) => this.onSearchTextChange(e.target.value)}
          />
        </div>

        <div className='diagnosis-selector'>
          {diagnosisOptions}
        </div>
      </form>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateCondition: updateCondition
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(DiagnosisStep)

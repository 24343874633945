// This is a place to dump enums, strings, etc. that are used in components, action creators, and reducer files. Try to keep it somewhat organized.

export const ARROW_DIRECTIONS = {
  PREV: 'PREV',
  NEXT: 'NEXT'
}

export const KNOWLEDGE_TYPES = {
  KNOWN: 'KNOWN',
  UNKNOWN: 'UNKNOWN',
  UNCERTAIN: 'UNCERTAIN'
}

export const SINGLE_STEP_TYPES = {
  INITIAL: 'Initial View',
  REJECT: 'Rejected View',
  KNOWLEDGE: 'Known Condition',
  DIAGNOSIS: 'Diagnosis Code',
  STATUS: 'Condition Status',
  NOTE: 'Adding Note'
}

export const WORKFLOW_TYPES = {
  SINGLE_STEP: [
    'documented',
    'suspected',
    'clinicalinference'
  ],
  MULTI_STEP: [
    'recommended'
  ]
}

// Any screen this wide or wider will be two columns. If narrower, it will be one column.
export const SINGLE_COLUMN_MAX_WIDTH = 800;

export const NOTIFICATION_CATEGORIES = {
  INFO: 'INFO',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  NO_PATIENT_DATA: 'NO_PATIENT_DATA'
}

// When a retryable error occurs, limit the number of times the user can click the retry button
export const RETRY_ATTEMPT_LIMIT = 2

export const RETRYABLE_ERROR_CODES = [
  'COULD_NOT_LOCK_VISIT_DIAGNOSES',
  'COULD_NOT_LOCK_PROBLEM_LIST'
]

export const ERROR_CODE_TO_RETRY_TEXT = {
  'COULD_NOT_LOCK_VISIT_DIAGNOSES': 'Visit Diagnoses is now closed. Retry my submission.',
  'COULD_NOT_LOCK_PROBLEM_LIST': 'Problem List is now closed. Retry my submission.'
}

import React from 'react'

export default class CollapsableHeader extends React.Component {
  render () {
    let {
      title,
      closedText, // Only show this when the header is collapsed and this field is not empty
      onClick,
      open,
      icon
    } = this.props

    const classNames = open ? 'open' : 'closed'
    const showClosedText = !open && closedText && closedText.length
    let titleClassNames = 'static-text'

    if (showClosedText) {
      titleClassNames += ' visible-closed-text'
    }

    return (
      <header className={classNames} onClick={onClick}>
        <div className='title'>
          <div className={titleClassNames}>
            {title}
          </div>

          {showClosedText && (
            <div className='closed-text'>
              {closedText}
            </div>
          )}
        </div>

        {icon}

        <div className='triangle'></div>
      </header>
    )
  }
}

import initialState from '../initialState'
import actionTypes from '../actionTypes'

function convertConditionsToArray(conditions) {
  return Object.keys(conditions).map(key => conditions[key])
}

function filterConditionsByProviderKnowledge(conditions, knowledge) {
  if (conditions) {
    return conditions.filter(condition => {
      if (knowledge === 'ACCEPTED') {
        return (
          condition.providerKnowledge === 'ACCEPTED' ||
          condition.providerKnowledge === 'ACCEPTED_DOCUMENT_SEPARATELY'
        )
      } else {
        return condition.providerKnowledge === knowledge
      }
    })
  } else {
    return [];
  }
}

function filterConditionsByType(conditions, type) {
  if (conditions) {
    return conditions.filter(condition => condition.type === type)
  } else {
    return [];
  }
}

const reducer = (state = initialState.reviewSubmission, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_FOR_REVIEW: {
      switch (action.status) {
        case 'success': {
          const { reviewText, patient, id, conditions } = action.data

          let conditionArray = convertConditionsToArray(conditions)

          return {
            ...state,
            reportId: id,
            reviewText,
            patient,
            documentedConditions: filterConditionsByType(conditionArray, 'DOCUMENTED'),
            suspectedConditions: filterConditionsByType(conditionArray, 'SUSPECTED'),
            acceptedConditions: filterConditionsByProviderKnowledge(conditionArray, 'ACCEPTED'),
            rejectedConditions: filterConditionsByProviderKnowledge(conditionArray, 'REJECTED'),
            isReviewingConditions: true,
            isSubmittingForReview: false
          }
        }

        case 'error': {
          return {
            ...state,
            isSubmittingForReview: false
          }
        }

        default: {
          return {
            ...state,
            isSubmittingForReview: true
          }
        }
      }
    }

    case actionTypes.SIGN_AND_SUBMIT: {
      switch (action.status) {
        case 'success': {
          let keepReviewOpen = false
          if (action.keepReviewOpen) {
            keepReviewOpen = true
          }

          return {
            ...state,
            signing: false,
            signed: true,
            isReviewingConditions: keepReviewOpen,
            isSubmissionModalOpen: true,
            lockedError: {
              ...state.lockedError,
              shouldRetry: false, // Done with this - submission was successful
              lockedCount: 0,
            }
          }
        }

        case 'error': {
          const newLockedCount = state.lockedError.lockedCount + 1

          return {
            ...state,
            signing: false,
            signed: false,
            lockedError: {
              ...state.lockedError,
              lockedCount: newLockedCount,
              shouldRetry: false,
            }
          }
        }

        default: {
          return {
            ...state,
            signing: true,
            signed: false
          }
        }
      }
    }

    // Review screen should never be open when a condition is
    case actionTypes.OPEN_CONDITION: {
      return {
        ...state,
        isReviewingConditions: false
      }
    }

    // This is called when the Review Modal is closed
    case actionTypes.CLOSE_REVIEW: {
      return {
        ...state,
        isReviewingConditions: false
      }
    }

    case actionTypes.CLOSE_SUBMISSION_MODAL: {
      return {
        ...state,
        isSubmissionModalOpen: false
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
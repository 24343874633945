// Packages
import React from 'react'
import Component from '../../common/Component'

export default class ReasonSelector extends Component {
    render() {
        const {
            condition,
            onOptionSelect
        } = this.props

        if (condition == null || condition.id == null || condition.providerKnowledge == null || condition.currentStep == null) {
            return null
        }

        return (
            <div className={'radio-options-container ' + condition.providerKnowledge.toLowerCase()}>
                {condition.currentStep.options.map((option, index) => {
                    const optionId = condition.id + '_' + condition.providerKnowledge + '-' + index

                    // Assumption: The option description is like: "Confirmed - This is the description"
                    const parts = option.description.split(/ [-–] /) // hypen or emdash
                    let title = parts[0] || null
                    let desc = parts[1] || null

                    // If the description isn't like that, then just display the description verbatim
                    if (title == null || desc == null || title.length === 0 || desc.length === 0) {
                        title = null
                        desc = option.description
                    }

                    let radioSquareClasses = 'radio-square'
                    if (condition.reasonOption != null && condition.reasonOption.id === option.id) {
                        radioSquareClasses += ' selected'
                    }

                    return (
                        <div key={optionId} className='radio-option' onClick={() => { onOptionSelect(option) }}>
                            <div className={radioSquareClasses} />

                            {title &&
                            <div className='radio-option-title'>
                                {title}&nbsp;–&nbsp;
                            </div>
                            }

                            <div className='radio-option-desc'>
                                {desc}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

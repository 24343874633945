import Filter from 'bad-words'
let filter = new Filter()

/*
  Generates a short, readable session ID that is unique enough for our purposes. These are NOT to
  be used for any security purposes - only for identifying user sessions throughout our stack.
*/

// Generate <count> random integers between 0 and <range>
function getRandomIntegers (count, range) {
  let result = []

  while (0 < count--) {
    result.push(Math.floor(Math.random() * range))
  }

  return result
}

// Returns a session ID (string) of 8 chars, with a hyphen between the 4th and 5th chars.
function generateId () {
  const CHAR_SET = '1234567890ABCDEFGHIJKLMNPQRSTUVWXYZ'
  const ID_LENGTH = 8

  let id = ''
  let bytes = getRandomIntegers(ID_LENGTH, CHAR_SET.length)

  for (let i = 0; i < ID_LENGTH; i++) {
    id += CHAR_SET[bytes[i]]

    if (i === 3) {
      id += '-'
    }
  }

  return id
}

// Returns a relatively unique session ID to track this user's actions.
function getSessionId() {
  let sessionId = generateId()

  // Forbid any session ID that contains profanity
  while (filter.isProfane(sessionId)) {
    sessionId = generateId()
  }

  return sessionId
}

export default getSessionId

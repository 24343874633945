// Packages
import React from 'react'

// Components
import Component from '../../../common/Component'
import ConditionBody from './ConditionBody'
import AcceptedIcon from '../../../common/icons/AcceptedIcon'

class AcceptedConditions extends Component {
  render () {

    const { conditions } = this.props
    let renderedConditions = conditions.map((condition, index) => {
      return (
        <ConditionBody
          condition={condition}
          conditionType='ACCEPTED'
          count={index + 1}
          key={index}
        />
      )
    })

    return (
      <section className='accepted-conditions'>
        <AcceptedIcon text='Accepted' />
        <div className='accepted-notice'>
          Upon submission, the following accepted diagnoses will be added to the patient's problem list:
        </div>
        {renderedConditions}
      </section>
    )
  }
}

export default AcceptedConditions

import React from 'react'
import Component from '../../common/Component'
import EvidenceHeader from './EvidenceHeader'
import EvidenceDocSnippet from './EvidenceDocSnippet'

// One piece of supporting evidence for a condition. Expects all props to be valid
class EvidenceWithDoc extends Component {
  render() {
    const {
      evidence,
      onViewDocument
    } = this.props

    return (
      <section className='evidence evidence-with-doc'>
        <EvidenceHeader
          evidenceName={evidence.dataSource}
          docType={evidence.docType}
          dateOfService={evidence.dateOfService}
          docLink={evidence.fullLinkText}
          onViewDocument={onViewDocument} />

        <EvidenceDocSnippet
          text={evidence.displayText}
          author={evidence.author}
          location={evidence.location} />
      </section>
    )
  }
}

export default EvidenceWithDoc

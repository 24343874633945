// Redux helpers
import actionTypes from '../actionTypes'
import API_ROOT from '../apiService'
import { addError } from '../helpers/notificationHelper'
import store from '../store'

// Constants
import { SINGLE_STEP_TYPES, WORKFLOW_TYPES } from '../../constants/constants'

////////////////////////////////////////////////////////////////////////////////////////////////////
// Helper functions
////////////////////////////////////////////////////////////////////////////////////////////////////

// Given a condition, return an object with only the fields required or allowed by this endpoint.
export const pruneMultiStepCondition = (condition) => {
  const rd = condition.recommendedDiagnosis
  let diagnosis = null
  let statuses = null
    let note = condition.note

  if (rd) {
    diagnosis = {
      id: rd.id,
      idType: rd.idType,
      name: rd.name,
      description: rd.description,
      icd10Code: rd.icd10Code
    }
  }

  // Have to convert the selected reason option into a status integer
  if (condition.reasonOption && condition.reasonOption.id && condition.providerKnowledge === 'ACCEPTED') {
    statuses = [condition.reasonOption.id]
  }

  if (condition.providerKnowledge === 'REJECTED') {
    diagnosis = null
    note = condition.note || condition.reasonOption.description
  }

  // Only send fields which will be accepted by the submitForReview endpoint later
  return {
    id: condition.id,
    diagnosis: diagnosis,
    note: note,
    providerKnowledge: condition.providerKnowledge,
    statuses: statuses,
    completed: true
  }
}

// Given a condition, return an object with only the fields required or allowed by this endpoint.
export const pruneSingleStepCondition = (condition) => {
  let knowledge = null
  if (
    condition.optionSelected &&
    condition.optionSelected[SINGLE_STEP_TYPES.KNOWLEDGE] &&
    condition.optionSelected[SINGLE_STEP_TYPES.KNOWLEDGE].id
  ) {
    knowledge = condition.optionSelected[SINGLE_STEP_TYPES.KNOWLEDGE].id
  } else if (
      condition.optionSelected &&
      condition.optionSelected[SINGLE_STEP_TYPES.INITIAL] &&
      condition.optionSelected[SINGLE_STEP_TYPES.INITIAL].id
  ) {
      knowledge = condition.providerKnowledge
  }

  let diagnosis = null
  if (
    condition.optionSelected &&
    condition.optionSelected[SINGLE_STEP_TYPES.DIAGNOSIS]
  ) {
    const diagnosisOption = condition.optionSelected[SINGLE_STEP_TYPES.DIAGNOSIS]
    diagnosis = {
      id: diagnosisOption.id,
      idType: 'ICD-10-CM',
      description: diagnosisOption.description,
      name: diagnosisOption.value
    }
  }

    let note = condition.optionSelected[SINGLE_STEP_TYPES.NOTE]
    if (condition.providerKnowledge === 'REJECTED') {
        diagnosis = null
        note = condition.note || condition.reasonOption.description
    }


    let statuses = []
  if (
    condition.optionSelected &&
    condition.optionSelected[SINGLE_STEP_TYPES.STATUS]
  ) {
    statuses = [condition.optionSelected[SINGLE_STEP_TYPES.STATUS].description]
  }

  return {
    id: condition.id,
    providerKnowledge: knowledge,
    diagnosis: diagnosis,
    note: note,
    statuses: statuses
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////
// Action Creators
////////////////////////////////////////////////////////////////////////////////////////////////////

// Update recommended condition data. Does not save to an endpoint - only changes the data in Redux.
export const updateCondition = conditionData => {
  return {
    type: actionTypes.UPDATE_CONDITION,
    condition: conditionData
  }
}

// User has decided to change which step of the single-step condition workflow they wish to view.
export const changeStep = (condition, direction) => {
  return {
    type: actionTypes.CHANGE_CONDITION_STEP,
    condition: condition,
    direction: direction
  }
}

// When the user clicks the Save button for a condition
// 'skipPruning' - set to true when conditionData is exactly what ought to be submitted
// 'openNextCondition' - true by default. Set to false to NOT open the next incomplete condition.
export const saveCondition = (conditionData, openNextCondition = true, cb) => {
  let limitedConditionData = null

  if (WORKFLOW_TYPES.SINGLE_STEP.indexOf(conditionData.type) !== -1) {
    limitedConditionData = pruneSingleStepCondition(conditionData)
  } else if (WORKFLOW_TYPES.MULTI_STEP.indexOf(conditionData.type) !== -1) {
    limitedConditionData = pruneMultiStepCondition(conditionData)
  } else {
    // This won't happen unless condition type is something unexpected
    throw new Error('Unknown condition.type: ' + conditionData.type)
  }

  return dispatch => {
    API_ROOT.request({
      method: 'put',
      headers: {
        Authorization: `Bearer ${store.getState().authentication.token}`,
        'Content-Type': 'application/json',
      },
      url:'/hcc/conditions',
      data: [limitedConditionData],
    }).then(response => {
      dispatch({
        type: actionTypes.SAVE_CONDITION,
        limitedCondition: limitedConditionData,
        condition: conditionData,
        openNextCondition: openNextCondition
      })

      if (cb) {
        cb()(dispatch)
      }
    }).catch(error => {
      addError(dispatch, error)
    })
  }
}

// User has decided to change which condition is being viewed. Set this condition as active.
export const openCondition = condition => {
  return {
    type: actionTypes.OPEN_CONDITION,
    condition: condition
  }
}

// User has decided to change which condition is being viewed. Unset this condition as active.
export const closeCondition = condition => {
  return {
    type: actionTypes.CLOSE_CONDITION,
    condition: condition
  }
}

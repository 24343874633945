// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'

// Action Creators
import { updateCondition, saveCondition } from '../../../store/actions/conditions'
import { saveConditionAndSubmitForReview } from '../../../store/actions/signAndSubmit'

class InitialStep extends Component {
    // Returns an object with the fields needed to maintain the condition's step state
    getNewStepFields(condition, option) {
        let newCurrentStep = condition.currentStep
        let newCurrentStepIndex = condition.currentStepIndex
        let newPrevStepIndex = condition.prevStepIndex
        let newNextStepIndex = condition.nextStepIndex
        let newPath = condition.path
        let checkStep = null
        let shouldSave = false

        // Change the step if a new option was selected
        if (option != null) {
            if (condition.currentStep.isSequential) {
                // The new currentStep should be the next step in the workflow
                checkStep = condition.workflow.steps[parseInt(condition.currentStepIndex, 10) + 1] || null
            } else if (option.nextStep != null) {
                // The new currentStep should be whichever one is specified in this option
                checkStep = condition.workflow.steps[option.nextStep] || null
            } else {
                // Option is not null, but the nextStep is null, and it's not sequential - this is the end!
                shouldSave = true
            }

            if (checkStep != null) {
                // Go to the next step
                newCurrentStep = checkStep
                newCurrentStepIndex = checkStep.id
                newPrevStepIndex = condition.currentStepIndex
                newNextStepIndex = null

                // Add the new current step to the path
                let pathCopy = condition.path.slice(0)
                pathCopy.push(newCurrentStepIndex)
                newPath = pathCopy
            }
        }

        return {
            currentStep: newCurrentStep,
            path: newPath,
            currentStepIndex: newCurrentStepIndex,
            prevStepIndex: newPrevStepIndex,
            nextStepIndex: newNextStepIndex,
            shouldSave: shouldSave
        }
    }

    // Called when an initial option is selected (NB: not necessarily when it changes)
    onOptionSelect(option) {
        const condition = this.props.condition
        let activeBtnId = null
        let activeBtnText = null
        let completed = false

        if (option == null || option.id == null) {
            console.error('Cannot select initial option: option.id is null')
            return
        }

        let newNextStepIndex = condition.nextStepIndex
        let newPath = condition.path

        // Did the option change? If so, recalculate state
        if (condition.initialOption == null || condition.initialOption.id !== option.id) {

            if (option.id === 'ACCEPTED') {
                activeBtnId = 'ACCEPTED'
                activeBtnText = 'Accepted'

            } else if (option.id === 'REJECTED') {
                activeBtnId = 'REJECTED'
                activeBtnText = 'Rejected'
            }

            let updatedCondition = {
                ...condition,
                providerKnowledge: option.id,
                note: null, // Always reset note when changing the initial option
                completed: completed,
                optionSelected: {
                    ...condition.optionSelected,
                    [condition.currentStep.name]: option
                },
                nextStepIndex: newNextStepIndex,
                path: newPath
            }

            // Local state, not tracked by Redux
            this.setState({
                activeBtnId: activeBtnId,
                activeBtnText: activeBtnText
            })

            // Determine next step, and if we're done with this condition
            let newStepFields = this.getNewStepFields(updatedCondition, option)
            let shouldSave = newStepFields.shouldSave
            delete newStepFields.shouldSave // Don't save this field to Redux

            updatedCondition = {
                ...updatedCondition,
                ...newStepFields
            }

            this.props.updateCondition(updatedCondition)
            // If it was determined that the workflow is over, then save the condition too
            if (shouldSave) {
                this.props.saveCondition(updatedCondition)
            }
        }
    }

    // When Redux state is changed, we need to update local state to match
    updateUI(props) {
        if (props.condition == null) {
            return
        }

        let activeBtnId = null
        let activeBtnText = null

        if (props.condition.providerKnowledge === 'ACCEPTED') {
            activeBtnId = 'ACCEPTED'
            activeBtnText = 'Agreed'
        } else if (props.condition.providerKnowledge === 'REJECTED') {
            activeBtnId = 'REJECTED'
            activeBtnText = 'Disagreed'
        }

        // Local state, not tracked by Redux
        return {
            activeBtnId: activeBtnId,
            activeBtnText: activeBtnText
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState(this.updateUI(nextProps))
    }

    constructor(props) {
        super(props)

        this._bindMethods([
            'onOptionSelect',
            'updateUI'
        ])

        // Setup a shorthand for the option objects
        let acceptOption = null
        let rejectOption = null

        props.condition.currentStep.options.forEach(option => {
            if (option.id === 'ACCEPTED') {
                acceptOption = option
            } else if (option.id === 'REJECTED') {
                rejectOption = option
            }
        })

        this.state = {
            ...this.updateUI(props),
            acceptOption: acceptOption,
            rejectOption: rejectOption
        }
    }

    render() {
        const {
            condition
        } = this.props

        const {
            activeBtnId,
            activeBtnText,
            documentSeparatelyActive,
            acceptOption,
            rejectOption,
            documentSeparatelyOption
        } = this.state

        return (
            <section className='multi-step initial-step'>
                <header>
                    <h1>
                        {condition.hcc.name}
                    </h1>
                </header>
                <div className='option-buttons'>
                    {[acceptOption, rejectOption].map((option) => {
                        return (
                            <span key={"span" + option.id} style = {{
                                marginRight: 5
                            }}>
                            <button
                                key={option.id}
                                ref={option.id}
                                className={(activeBtnId === option.id || !activeBtnId ? 'active' : '') + ' ' + option.description.toLowerCase()}
                                onClick={() => {this.onOptionSelect(option)}}
                                type='button'>
                                {activeBtnId !== option.id ? option.description : activeBtnText}
                            </button>
                            </span>
                        )
                    })}
                </div>

                {documentSeparatelyOption &&
                <div className='link-option'>
            <span
                className={documentSeparatelyActive ? 'active' : ''}
                onClick={() => {this.onOptionSelect(documentSeparatelyOption)}}>

              {documentSeparatelyOption.description}
            </span>
                </div>
                }
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    recommendedConditions: state.conditions.recommended
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateCondition: updateCondition,
        saveCondition: saveCondition,
        saveConditionAndSubmitForReview: saveConditionAndSubmitForReview
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialStep)

// Packages
import React from 'react'

// Components
import Component from '../Component'
import CloseModalButton from '../Button/CloseModalButton'

export default class Dialog extends Component {
  open() {
    this.setState({
      isOpen: true,
    })
  }

  close() {
    document.body.classList.remove('modal-open')

    this.setState({
      isOpen: false,
    })

    this.props.onClose()
  }

  handleForeignClick(event) {
    const {
      isModal
    } = this.props

    const {
      isOpen
    } = this.state

    let dialogContainsTarget = this.dialogEl && this.dialogEl.contains(event.target)
    let targetIsDialog = event.target === this.dialogEl

    if (!isModal && isOpen && !targetIsDialog && !dialogContainsTarget) {
      this.close()
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleForeignClick)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleForeignClick)
    document.body.classList.remove('modal-open')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.state.isOpen) {
      this.setState({
        isOpen: nextProps.isOpen,
      })
    }
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'open',
      'close',
      'handleForeignClick'
    ])

    this.state = {
      isOpen: props.isOpen ? true : false,
    }
  }

  render() {
    const {
      children,
      className,
      hideCloseButton
    } = this.props

    const {
      isOpen
    } = this.state

    let overLayClasses = 'modal-overlay'

    if (isOpen) {
      document.body.classList.add('modal-open')
      overLayClasses += ' open'
    }

    return (
      <div className={overLayClasses}>
        <dialog
          className={className}
          open={isOpen}
          ref={this.dialogEl}
        >
          <div className='modal-content'>
            {children}
          </div>

          {!hideCloseButton &&
            <CloseModalButton onClick={this.close}/>
          }
        </dialog>
      </div>
    )
  }
}

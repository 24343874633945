import actionTypes from '../actionTypes'
import initialState from '../initialState'

const reducer = (state = initialState.evidenceDocument, action) => {
  switch (action.type) {
    case actionTypes.GET_EVIDENCE_DOCUMENT:
      switch (action.status) {
        case 'success':
          return {
            ...state,
            fetching: false,
            data: action.data,
            error: null
          }

        case 'error':
          return {
            ...state,
            fetching: false,
            data: null,
            error: action.error
          }

        default:
          return {
            ...state,
            fetching: true
          }
      }

    default:
      return {
        ...state
      }
  }
}

export default reducer

// Packages
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// Components
import Component from '../Component'

class CloseModalButton extends Component {
  render() {
    return (
      <FontAwesomeIcon
        icon={faTimes}
        className='close-modal'
        onClick={this.props.onClick}
      />
    )
  }
}

export default CloseModalButton

// Redux helpers
import actionTypes from '../actionTypes'
import initialState from '../initialState'

// Helper functions
import { Helper } from '../helpers/conditions/helper'

// Constants
import {
  ARROW_DIRECTIONS,
  WORKFLOW_TYPES
} from '../../constants/constants'

const reducer = (state = initialState.conditions, action) => {
  switch (action.type) {
    case actionTypes.HCC_DATA_GET: {
      switch (action.status) {
        case 'success': {
          let recommendedWorkflow = false
          if (action.recommended.length > 0 || action.clinicalinference.length > 0) {
            recommendedWorkflow = true
          }

          // Add the fields needed by the UI (needed if some conditions are completed)
          action.recommended = Helper.setConditionUI(action.recommended)
          action.documented = Helper.setConditionUI(action.documented)
          action.suspected = Helper.setConditionUI(action.suspected)
          action.clinicalinference = Helper.setConditionUI(action.clinicalinference)

          // Determine the active condition (which one is open) for each condition list
          let recActiveCondition = Helper.getNextUncompletedCondition(action.recommended)
          let docActiveCondition = Helper.getNextUncompletedCondition(action.documented)
          let susActiveCondition = Helper.getNextUncompletedCondition(action.suspected)
          let ciActiveCondition = Helper.getNextUncompletedCondition(action.clinicalinference)

          // Global allCompleted flag is only true when all conditions are completed
          let allCompleted = (
            recActiveCondition == null &&
            docActiveCondition == null &&
            susActiveCondition == null &&
            ciActiveCondition == null
          )

          return {
            ...state,
            fetching: false,
            fetched: true,
            recommendedWorkflow: recommendedWorkflow,
            allRequired: action.meta.isAllRequired,
            allCompleted: allCompleted,
            canSubmitForReview: Helper.canSubmitForReview(state, action.recommended),
            recommended: {
              allCompleted: recActiveCondition == null,
              active: recActiveCondition,
              list: action.recommended
            },
            documented: {
              allCompleted: docActiveCondition == null,
              active: docActiveCondition,
              list: action.documented
            },
            suspected: {
              allCompleted: susActiveCondition == null,
              active: susActiveCondition,
              list: action.suspected
            },
              clinicalinference: {
                  allCompleted: ciActiveCondition == null,
                  active: ciActiveCondition,
                  list: action.clinicalinference
              }
          }
        }

        case 'hasNoConditionData': {
          return {
            ...state,
            fetching: false,
            fetched: true
          }
        }

        case 'error': {
          return {
            ...state,
            fetching: false,
            fetched: true
          }
        }

        default: {
          return {
            ...state,
            fetching: true,
            fetched: false
          }
        }
      }
    }

    // Updates the condition data for one condition.
    // For Single-Step conditions, it moves the currentStep forward if needed.
    case actionTypes.UPDATE_CONDITION: {
      let condition = action.condition

      // For single steps, we need to check specific condition data
      if (WORKFLOW_TYPES.SINGLE_STEP.indexOf(condition.type) !== -1) {
        condition = Helper.singleStep.updateUI(condition)
      }

      // Update the condition data in its condition list
      const updatedConditions = state[condition.type].list.map(currentCondition => {
        if (currentCondition.id === condition.id) {

          // Did this change result in the condition being able to be saved?
          condition.canSave = Helper.canSaveCondition(condition)

          return condition
        } else {
          return currentCondition
        }
      })

      return {
        ...state,
        canSubmitForReview: Helper.canSubmitForReview(state, updatedConditions),
        [condition.type]: {
          active: state[condition.type].active,
          allCompleted: state[condition.type].allCompleted,
          list: updatedConditions
        }
      }
    }

    // Based on the condition's current step, move in the direction specified (previous or next).
    // Does not change the condition.path stack, but will shift the current, prev, and next step
    // fields by one step (depending on the direction of change: prev XOR next)
    case actionTypes.CHANGE_CONDITION_STEP: {
      let condition = action.condition

      // Only allow single-step workflows to change the current step
      if (WORKFLOW_TYPES.SINGLE_STEP.indexOf(condition.type) === -1) {
        return {
          ...state
        }
      }

      // TODO: Send this logic to the Condition Helper

      let found = false // break flag in the for-loop
      let newCurrentStep = Object.assign({}, condition.currentStep)
      let newCurrentIndex = condition.currentStepIndex
      let newPrevIndex = condition.prevStepIndex
      let newNextIndex = condition.nextStepIndex

      // Set the current, next, and previous condition appropriately, based on direction.
      if (action.direction === ARROW_DIRECTIONS.PREV) {
        newCurrentIndex = condition.prevStepIndex
        newCurrentStep = Object.assign({}, condition.workflow.steps[newCurrentIndex])
        newNextIndex = condition.currentStepIndex
        newPrevIndex = null

        // We should pick the step BEFORE the current prevStep as the new prevStep
        for (let i = 0; i < condition.path.length; i++) {
          if (found) {
            break
          }

          // Anticipate the step the iterator will hit on the next i value (i + 1). If that value
          // is the same as the current prevStep, then step[i] should be the NEW prevStep value
          if (condition.path[i + 1] === condition.prevStepIndex) {
            found = true
            newPrevIndex = condition.path[i]
          }
        }
      } else if (action.direction === ARROW_DIRECTIONS.NEXT) {
        newCurrentIndex = condition.nextStepIndex
        newCurrentStep = Object.assign({}, condition.workflow.steps[newCurrentIndex])
        newPrevIndex = condition.currentStepIndex
        newNextIndex = null

        // We should pick the step AFTER the current nextStep as the new nextStep
        for (let i = 0; i < condition.path.length; i++) {
          if (found) {
            break
          }

          // If that current iterator value is the same as the current nextStep, then step[i + 1]
          // should be the NEW nextStep value.
          if (condition.path[i] === condition.nextStepIndex) {
            found = true
            newNextIndex = condition.path[i + 1] || null
          }
        }
      }

      // Update the currentStep for this condition. All other conditions remain the same.
      const updatedConditions = state[condition.type].list.map(currentCondition => {
        if (currentCondition.id === condition.id) {
          return {
            ...condition,
            currentStep: newCurrentStep,
            currentStepIndex: newCurrentIndex,
            prevStepIndex: newPrevIndex,
            nextStepIndex: newNextIndex
          }
        } else {
          return currentCondition
        }
      })

      return {
        ...state,
        [condition.type]: {
          active: state[condition.type].active,
          allCompleted: state[condition.type].allCompleted,
          list: updatedConditions
        }
      }
    }

    // Called when the Save button is clicked. Marks the saved condition as completed, and sets
    // activeCondition and allCompleted.
    case actionTypes.SAVE_CONDITION: {
      let condition = action.condition

      // Mark condition as completed
      const updatedConditions = state[condition.type].list.map(currentCondition => {
        if (currentCondition.id === condition.id) {
          return {
            ...condition,
            completed: true
          }
        } else {
          return currentCondition
        }
      })

      // Set new active condition and the allCompleted flag
      let localAllCompleted = false
      let nextCondition = Helper.getNextUncompletedCondition(updatedConditions)

      if (nextCondition == null) {
        localAllCompleted = true
      }

      // Do not open the next condition on save if the user's action implies it should not be opened
      if (action.openNextCondition === false) {
        nextCondition = null
      }

      return {
        ...state,
        allCompleted: Helper.checkAllCompleted(state, localAllCompleted, condition.type),
        canSubmitForReview: Helper.canSubmitForReview(state, updatedConditions),
        [condition.type]: {
          active: nextCondition,
          allCompleted: localAllCompleted,
          list: updatedConditions
        }
      }
    }

    // Reset the active condition data, if another condition is already active.
    // Then open the selected condition by setting it as the activeCondition.
    case actionTypes.OPEN_CONDITION: {
      let condition = action.condition

      if (state[condition.type].active != null) {
        let revertedConditions = Helper.revertConditionData(state[condition.type].active, state[condition.type].list)

        return {
          ...state,
          allCompleted: false,
          [condition.type]: {
            active: condition,
            list: revertedConditions
          }
        }
      } else {
        return {
          ...state,
          allCompleted: false,
          [condition.type]: {
            active: condition,
            allCompleted: false,
            list: state[condition.type].list
          }
        }
      }
    }

    // Restore the condition data from activeCondition to this condition in the condition list.
    // This will reset any unsaved changes to the condition.
    case actionTypes.CLOSE_CONDITION: {
      let condition = action.condition

      let revertedConditions = Helper.revertConditionData(state[condition.type].active, state[condition.type].list)

      // Check to see if all are completed
      let localAllCompleted = false
      if (Helper.getNextUncompletedCondition(state[condition.type].list) == null) {
        localAllCompleted = true
      }

      return {
        ...state,
        allCompleted: Helper.checkAllCompleted(state, localAllCompleted, condition.type),
        canSubmitForReview: Helper.canSubmitForReview(state, revertedConditions),
        [condition.type]: {
          active: null,
          allCompleted: localAllCompleted,
          list: revertedConditions
        }
      }
    }

    // When submitting for review, do not save the active condition. If the user wants to save the
    // active condition (and is able to) then SaveCondition will be called before Submit for Review.
    case actionTypes.SUBMIT_FOR_REVIEW: {
      if (action.status === 'success') {
        // For the recommended workflow, reset the active condition
        let revertedConditions = Helper.revertConditionData(state.recommended.active, state.recommended.list)

        return {
          ...state,
          recommended: {
            ...state.recommended,
            active: null,
            list: revertedConditions
          }
        }
      } else {
        return state
      }
    }

    default:
      return state
  }
}

export default reducer

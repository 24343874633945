// Packages
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

// Components
import Component from '../Component'

class Loading extends Component {
  render() {
    return (
      <div className='loading-container'>
        <FontAwesomeIcon icon={faSpinner} spin pulse />

        <div>
          Loading
        </div>
      </div>
    )
  }
}

export default Loading

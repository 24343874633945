// Packages
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

// Components
import Component from '../../../common/Component'
import AsyncButton from '../../../common/Button/AsyncButton'
import AcceptedConditions from './AcceptedConditions'
import RejectedConditions from './RejectedConditions'

// Action Creators
import * as signAndSubmitActions from '../../../../store/actions/signAndSubmit'

class MultiStepReview extends Component {
  render() {
    const {
      onSubmit
    } = this.props

    const {
      acceptedConditions,
      rejectedConditions,
      reviewText,
      patient,
      signing,
      signed
    } = this.props.reviewSubmission

    let acceptedSection = null
    let rejectedSection = null

    if (acceptedConditions.length) {
      acceptedSection = <AcceptedConditions conditions={acceptedConditions}/>
    }

    if (rejectedConditions.length) {
      rejectedSection = <RejectedConditions conditions={rejectedConditions}/>
    }

    return (
      <div className='multi-step-review'>
        <div className='review-summary'>
          <div className='h1'>
            <h1 className='review-title'>Review of Diagnoses Documentation</h1>
          </div>

          <div className='h2'>
            <h3>{patient.fullName || 'No name found.'}</h3>
            <h3>
              <span>MRN: {patient.mrn || 'Not found'}</span>
              <span>DOB: {patient.dateOfBirth || 'Not found.'}</span>
            </h3>
          </div>

          {acceptedSection}
          {rejectedSection}

        </div>

        <div className='review-signature'>
          <p>
            {reviewText}
          </p>

          {!signed &&
            <AsyncButton
              pending={signing}
              onClick={onSubmit}
            >
              <FontAwesomeIcon icon={faPlus} className='prefix-icon' />
              Add to Encounter
            </AsyncButton>
          }

          {/* This only exists to force padding below the button. IE 10 will ignore padding added to the review-signature div, or as a pseudo-element, e.g. ::after { content: ''; } */}
          <div className='ie-10-spacer'></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  reviewSubmission: state.reviewSubmission
})

export default connect(mapStateToProps, {...signAndSubmitActions})(MultiStepReview)

// Packages
import React from 'react'

// Components
import Component from '../../../common/Component'

class ConditionFields extends Component {
  render () {
    let {
      type,
      value
    } = this.props

    if (type == null || typeof type !== 'string') {
      return (
        <span></span>
      )
    }

    const fieldValue = value || `No ${type.toLowerCase()} provided.`

    return (
      <div>
        <span className='field-name'>
          {type + ':'}
        </span>
        <span className='field-value'>
          {fieldValue}
        </span>
      </div>
    )
  }
}

export default ConditionFields

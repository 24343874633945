// Packages
import React from 'react'

// Components
import Component from '../Component'

export default class Button extends Component {
  render () {
    let {
      classNames
    } = this.props

    const {
      children,
      disabled,
      onClick
    } = this.props

    if (classNames != null && classNames.length) {
      classNames += ' button'      
    } else {
      classNames = 'button'
    }

    if (disabled) {
      classNames += ' disabled'
    }

    return (
      <button
        type='button'
        className={classNames}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </button>
    )
  }
}

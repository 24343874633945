import React from 'react'
import { connect } from 'react-redux'
import { marked } from 'marked'

import Component from '../../common/Component'

class HccDisclaimer extends Component {
  render() {
    const disclaimer = this.props.disclaimer || ''
    let conditionCount = null
    let conditionStr = ''

    if (this.props.ciConditions && this.props.ciConditions.length) {
      conditionCount = this.props.ciConditions.length
    } else if (this.props.recommendedConditions && this.props.recommendedConditions.length) {
      conditionCount = this.props.recommendedConditions.length
    }

    if (conditionCount != null) {
      let s = 's'

      if (conditionCount === 1) {
        s = ''
      }

      conditionStr = '\n\nYou have ' + conditionCount + ' condition' + s + ' to review.'
    }

    const markdown = marked(disclaimer + conditionStr, {sanitize: true})

    return (
      <p className='condition-disclaimer' dangerouslySetInnerHTML={{__html: markdown}} />
    )
  }
}

const mapStateToProps = (state) => ({
  ciConditions: state.conditions.clinicalinference.list,
  recommendedConditions: state.conditions.recommended.list
})

export default connect(mapStateToProps, null)(HccDisclaimer)

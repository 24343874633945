// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'
import Collapsable from '../../common/Collapsable/Collapsable'
import CollapsableHeader from '../../common/Collapsable/CollapsableHeader'
import StepChanger from './StepChanger'
import Checkmark from '../../common/icons/CheckMark'
import Button from '../../common/Button/Button'
import SupportingEvidence from '../SupportingEvidence/SupportingEvidence'

import InitialStep from './InitialStep'
import RejectStep from './RejectStep'
import KnowledgeStep from './KnowledgeStep'
import DiagnosisStep from './DiagnosisStep'
import StatusStep from './StatusStep'
import NoteStep from './NoteStep'

// Action Creators
import { updateCondition, saveCondition } from '../../../store/actions/conditions'

// Constants
import { ARROW_DIRECTIONS, SINGLE_STEP_TYPES } from '../../../constants/constants'

// This is the container component for the Document and Suspected conditions workflows. Note that
// it's a 'single' step because only one step is visible at a time - not that there is only one
// step. This is in contrast to the MultiStepWorkflow, which can show multiple steps at the same time.
class SingleStepWorkflow extends Component {
  getIcon(completed) {
    if (completed) {
      return <Checkmark className='completed' />
    } else {
      return null
    }
  }

    // Given a condition, returns a string to use as the collapsableHeader's top-left heading.
    getTitle(condition) {
        if (condition.type === 'recommended' && condition.recommendedDiagnosis != null) {
            return condition.recommendedDiagnosis.icd10Code
        }
        if (condition.type !== 'recommended') {
            return condition.hcc.name
        }
    }

    // Given a condition, returns a string to use as the CollapsableHeader's closed heading.
    getClosedText(condition) {
        if (condition.type === 'recommended' && condition.recommendedDiagnosis != null) {
            return condition.recommendedDiagnosis.description
        }

        if (condition.type !== 'recommended') {
            return condition.hcc.name
        }
    }

  // Callback to tell MultiStepWorkflow the user completed this condition.
  onSaveClick() {
    if (this.props.condition.canSave) {
      this.props.saveCondition(this.props.condition)
    }
  }

  // Returns the local component state, as determined by 
  updateUI(props) {
    return {
      icon: this.getIcon(props.condition.completed),
      isPrevVisible: props.condition.prevStepIndex != null,
      isNextVisible: props.condition.nextStepIndex != null
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(this.updateUI(nextProps))
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'onSaveClick',
      'updateUI',
      'getIcon',
      'getTitle',
      'getClosedText'
    ])

    this.state = this.updateUI(props)
  }

  render() {
    const {
      isOpen,
      toggleCollapsable,
      condition
    } = this.props

    const {
      icon,
      isPrevVisible,
      isNextVisible
    } = this.state


    const {
      columns
    } = this.props.env

    if (condition == null || condition.currentStep == null) {
      return null
    }

    let classNames = ''
    if (isOpen) {
      classNames += ' active-condition'
    }

    return (
      <div className='single-step-workflow'>
        <Collapsable
          className={classNames}
          onClick={() => toggleCollapsable(condition)}
          open={isOpen}>

          <CollapsableHeader
              title={this.getTitle(condition)}
            open={isOpen}
            icon={icon}
          />

          <div className='step-container'>
            <StepChanger
              condition={condition}
              direction={ARROW_DIRECTIONS.PREV}
              isVisible={isPrevVisible}
            />
              {condition.currentStep.name === SINGLE_STEP_TYPES.INITIAL &&
              <InitialStep condition={condition} />
              }

              {condition.currentStep.name === SINGLE_STEP_TYPES.REJECT &&
              <RejectStep condition={condition} />
              }

            {condition.currentStep.name === SINGLE_STEP_TYPES.KNOWLEDGE &&
              <KnowledgeStep condition={condition} />
            }

            {condition.currentStep.name === SINGLE_STEP_TYPES.DIAGNOSIS &&
              <DiagnosisStep condition={condition} />
            }

            {condition.currentStep.name === SINGLE_STEP_TYPES.STATUS &&
              <StatusStep condition={condition} />
            }

            {condition.currentStep.name === SINGLE_STEP_TYPES.NOTE &&
              <NoteStep condition={condition} />
            }

            <StepChanger
              condition={condition}
              direction={ARROW_DIRECTIONS.NEXT}
              isVisible={isNextVisible}
            />
          </div>

          {condition.currentStep.showSaveButton &&
            <div className='save-container'>
              <Button
                classNames={condition.stylingClasses}
                disabled={!condition.canSave}
                onClick={this.onSaveClick}
              >
                Save
              </Button>
            </div>
          }
          {isOpen && columns === 1 && condition.type === 'clinicalinference' &&
            <SupportingEvidence condition={condition} />
          }
        </Collapsable>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    env: state.env
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateCondition: updateCondition,
    saveCondition: saveCondition
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleStepWorkflow)

import React from 'react'
import Component from '../../common/Component'
import { map } from 'lodash'

export default class TableProto extends Component {
  /***************************************************************************\
    Private Methods
  \***************************************************************************/

  /**
   * _renderStartMeasureColumns: render the column to table
   * @param {Object} column - the column to render into table
   * @param {String} column - the guid to use to map to column
   */
  _renderTableColumns(column, key, hasCellHeaders, width) {
    const columnId = column.columnId
    const firstColumn = '0' //columnId of 0
    let hasLargeWidth
    return {
      id: key,
      headerStyle: {
        textAlign: 'left',
        display:'flex',
        alignItems:'center',
        whiteSpace:'normal',
      },
      Header: () =>
        (columnId === firstColumn && hasCellHeaders
          ? <span className="cell-header">{column.header !== '' && `${column.header}:`}</span>
          : <span className="thead-header">{column.header}</span>),
      accessor: accessor => {
        if (typeof accessor[key] === 'undefined') {
          return 'N/A'
        }
        return accessor[key].value
      },
      Cell: props =>{
        hasLargeWidth = props.value.length >= 40 ? true : false
        return columnId === firstColumn && hasCellHeaders
          ? <span className="cell-header" data-lg-width={hasLargeWidth}>{props.value}</span>
          : <span className="cell-data" data-lg-width={hasLargeWidth}>{props.value}</span>

      },
      width: columnId === firstColumn || hasLargeWidth ? width : 'initial',
      hasCellHeader:hasCellHeaders
    }
  }

  /**
   * _mapPayloadColumns: maps over the columns to build columns
   * @param {Object} columns - columns to render into table
   */
  _mapPayloadColumns (columns, hasCellHeaders, width, cb) {
    if (columns.length === 0) return
    return map(columns, (column, key) => cb(column, key, hasCellHeaders, width))
  }
  /***************************************************************************\
    Public Methods
  \***************************************************************************/

  buildTableColumns (
    columns = {},
    hasCellHeaders,
    width = 'auto',
    renderColsFn = this._renderTableColumns
  ) {
    return this._mapPayloadColumns(columns, hasCellHeaders, width, renderColsFn)
  }
}

// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { map } from 'lodash'

// Action Creator
import { getPatientSummary } from '../../../store/actions/patientSummary'
import { getSectionViews } from '../../../store/reducers/patientSummary'

// Components
import Component from '../../common/Component'
import Collapsable from '../../common/Collapsable/Collapsable'
import CollapsableHeader from '../../common/Collapsable/CollapsableHeader'
import Button from '../../common/Button/Button'
import PatientSummaryTable from './PatientSummaryTable'
import SuccessDialog from '../../common/Dialog/SuccessDialog'

class PatientSummary extends Component {
  _onComplete(index) {
    this.setState({
      openItem: index,
    })
  }

  _handleCollapseAll() {
    this.setState({
      isCollapsedAll: !this.state.isCollapsedAll,
    })
  }

  _onOpen(index) {
    this.setState({ openItem: index })
  }

  handleFileToChart(isClosed) {
    if (isClosed) {
      return this.setState({
        isOpenFileToChart: false,
      })
    }

    // Add a small delay to make it seem like something actually happens when this is called
    setTimeout(() => {
      this.setState({
        isOpenFileToChart: !this.state.isOpenFileToChart,
      })

      if (window.parent) {
        window.parent.postMessage(true, '*')
      }
    }, 250)
  }

  componentDidMount() {
    this.props.getPatientSummary()
  }

  constructor(props) {
    super(props)

    this.state = {
      openItem: 0,
      isOpenFileToChart: false,
      isCollapsedAll: false,
    }

    this._bindMethods([
      '_onComplete',
      '_onOpen',
      'handleFileToChart',
      '_handleCollapseAll'
    ])
  }

  render() {
    if (this.props.isLoading) {
      return <div>Loading...</div>
    }

    const { sections } = this.props

    return (
      <section className='patient-summary'>
        <header>
          <Button
            classNames='pink'
            onClick={() => this.handleFileToChart(false)}
          >
            <FontAwesomeIcon icon={faChevronCircleLeft} className='prefix-icon' />
            File to chart
          </Button>
          <Button
            classNames='blue btn-expand-collapse'
            onClick={() => this._handleCollapseAll()}
          >
            {this.state.isCollapsedAll ? 'Collapse All' : 'Expand All'}
          </Button>
        </header>

        {map(sections, section => {
          return (
            <Collapsable
              key={section.key}
              open={this.state.isCollapsedAll}
              /*onClick={() => toggleCollapsable(condition)*/
            >
              <CollapsableHeader
                title={section.title}
                open={this.state.isCollapsedAll}
              />

              <PatientSummaryTable data={section} />
            </Collapsable>
          )
        })}

        <SuccessDialog isOpen={this.state.isOpenFileToChart}>
          <h1>File has been added to chart.</h1>
          <Button
            onClick={() => this.handleFileToChart(true)}
          >
            OK
          </Button>
        </SuccessDialog>
      </section>
    )
  }
}

const mapStateToProps = ({ patientSummary }) => {
  return {
    sections: getSectionViews(
      // The state to calculate
      patientSummary,
      // Sections to render
      ['patientQuality', 'healthConditionHistory', 'labResults', 'prescription', 'patientAdmission' ],
      // InnerTables for specific section(s)
      { patientQuality: ['starMeasure', 'careAlert'] },
      // Config for each section
      {
        // section
        patientQuality:{
          starMeasure:{
            firstColumnWidth: 250,
            header:true
          },
          careAlert:{
            firstColumnWidth: 250,
            header:false
          }
        },
        // section
        healthConditionHistory:{
          firstColumnWidth: 310,
          header:true
        },
      }
    ),
    isLoading: patientSummary.isLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getPatientSummary: getPatientSummary
  }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(PatientSummary)

// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { find } from 'lodash'

// Components
import Component from '../../common/Component'
import AsyncButton from '../../common/Button/AsyncButton'
import ProgressBar from './ProgressBar'

// Action Creators
import {
  submitForReview,
  saveConditionAndSubmitForReview
} from '../../../store/actions/signAndSubmit'

class ContinueToReview extends Component {
  // Normal behavior: Submits the current submission array for review.
  //
  // If this is the recommended workflow and an active condition is able to be saved, this function
  // first saves the condition, adds it to the submission array, and then submits for review.
  submitForReview() {
    if (this.props.recommendedWorkflow && this.props.recommendedConditions.active != null) {
      let conditionToSave = find(this.props.recommendedConditions.list, {
        'id': this.props.recommendedConditions.active.id
      })

      if (conditionToSave.canSave) {
        return this.props.saveConditionAndSubmitForReview(conditionToSave)
      }
    }

    this.props.submitForReview()
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'submitForReview'
    ])
  }

  render() {
    const {
      showProgressBar,
      columns,
      recommendedWorkflow,
      canSubmitForReview,
      isSubmittingForReview,
    } = this.props

    let classNames = 'continue-to-review'
    let reviewButtonClasses = ''

    if (recommendedWorkflow) {
      classNames += ' with-progress-bar'
      reviewButtonClasses = 'secondary'
    }

    return (
      <footer className={classNames}>
        {showProgressBar &&
          <ProgressBar />
        }

        <div className='button-container'>
          <AsyncButton
            disabled={!canSubmitForReview || this.props.allCompleted}
            classNames={reviewButtonClasses}
            pending={isSubmittingForReview}
            onClick={this.submitForReview}
          >
            Continue to Submission
            {columns === 2 &&
              <FontAwesomeIcon icon={faChevronCircleRight} className='suffix-icon' />
            }
          </AsyncButton>
        </div>
      </footer>
    )
  }
}

const mapStateToProps = (state) => ({
  allCompleted: state.conditions.allCompleted,
  columns: state.env.columns,
  recommendedConditions: state.conditions.recommended,
  recommendedWorkflow: state.conditions.recommendedWorkflow,
  canSubmitForReview: state.conditions.canSubmitForReview,
  isSubmittingForReview: state.reviewSubmission.isSubmittingForReview
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    submitForReview: submitForReview,
    saveConditionAndSubmitForReview: saveConditionAndSubmitForReview
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContinueToReview)

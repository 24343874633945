// Packages
import React from 'react'

// Components
import Component from '../../../common/Component'
import DocumentSeparatelyIcon from '../../../common/icons/DocumentSeparatelyIcon'

class ConditionBody extends Component {
  render() {
    let {
      condition,
      count,
      conditionType
    } = this.props

    if (condition == null) {
      return null
    }

    let noteSection = null
    let statusText = ''

    if (condition.statuses && condition.statuses.length > 0) {
      statusText = condition.statuses[0] + ' '
    }

    if (condition.note) {
      statusText += condition.note
    }

    if (condition.providerKnowledge === 'ACCEPTED_DOCUMENT_SEPARATELY') {
      noteSection = (
        <div className='condition-note'>
          <DocumentSeparatelyIcon
            text={'Discussed in progress note'}
            textColorSameAsIcon={true}
            bold={true}
          />
        </div>
      )
    } else if (statusText != null && statusText.length) {
      noteSection = (
        <div className='condition-note'>
          <p>Note: {statusText}</p>
        </div>
      )
    }

    let conditionBodyText = ''
    if (condition.recommendedDiagnosis) {
      conditionBodyText = condition.recommendedDiagnosis.description + '  (' + condition.recommendedDiagnosis.icd10Code + ')'
    } else {
      conditionBodyText = condition.hcc.name + '  (HCC ' + condition.hcc.id + ')'

    }
    return (
      <section className='condition-block'>
        <header data-condition-state={conditionType.toUpperCase()}>
          <div className='header-content'>
            <div className='circle'>
              {count}
            </div>
            <span>
              {conditionBodyText}
            </span>
          </div>
        </header>

        {noteSection}
      </section>
    )
  }
}

export default ConditionBody

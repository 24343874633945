import { combineReducers } from 'redux';
import conditions from './conditions';
import patientSummary from './patientSummary';
import authentication from './authentication';
import submission from './submission';
import reviewSubmission from './signAndSubmit';
import env from './env';
import evidenceDocument from './getEvidenceDocument';
import notification from './notification';

const rootReducer = combineReducers({
  submission,
  authentication,
  conditions,
  patientSummary,
  reviewSubmission,
  env,
  evidenceDocument,
  notification,
});

export default rootReducer;

// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'
import Collapsable from '../../common/Collapsable/Collapsable'
import CollapsableHeader from '../../common/Collapsable/CollapsableHeader'
import SupportingEvidence from '../SupportingEvidence/SupportingEvidence'
import InitialStep from './InitialStep'
import ReasonStep from './ReasonStep'
import AcceptedIcon from '../../common/icons/AcceptedIcon'
import RejectedIcon from '../../common/icons/RejectedIcon'
import DocumentSeparatelyIcon from '../../common/icons/DocumentSeparatelyIcon'

// Action Creators
import { updateCondition } from '../../../store/actions/conditions'

class MultiStepWorkflow extends Component {
  getIcon(providerKnowledge) {
    if (providerKnowledge === 'ACCEPTED') {
      return <AcceptedIcon text={'Accepted'} />
    } else if (providerKnowledge === 'REJECTED') {
      return <RejectedIcon text={'Rejected'} />
    } else if (providerKnowledge === 'ACCEPTED_DOCUMENT_SEPARATELY') {
      return <DocumentSeparatelyIcon text={'Accepted'} />
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      icon: this.getIcon(nextProps.condition.providerKnowledge)
    })
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'getIcon'
    ])

    this.state = {
      icon: this.getIcon(props.condition.providerKnowledge)
    }
  }

  render() {
    const {
      isOpen,
      toggleCollapsable,
      condition
    } = this.props

    const {
      columns
    } = this.props.env

    const {
      icon
    } = this.state

    let classNames = 'recommended-condition'
    if (isOpen) {
      classNames += ' active-condition'
    }

    // Hides the reasonStep if the condition is collapsed or user hasn't selected an initial option
    let reasonClassNames = 'reason-step-container'
    if (!isOpen) {
      reasonClassNames += ' hidden'
    }

    return (
      <div className='multi-step-workflow'>
        <Collapsable
          className={classNames}
          onClick={() => toggleCollapsable(condition)}
          open={isOpen}>

          <CollapsableHeader
            title={condition.recommendedDiagnosis.icd10Code}
            closedText={condition.recommendedDiagnosis.name}
            open={isOpen}
            icon={icon}
          />

          <InitialStep
            condition={condition}
          />

          {isOpen && columns === 1 && condition.type === 'recommended' &&
            <SupportingEvidence condition={condition} />
          }
        </Collapsable>

        <div className={reasonClassNames}>
          <ReasonStep
            condition={condition}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  env: state.env
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateCondition: updateCondition
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiStepWorkflow)

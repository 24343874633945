// Packages
import React from 'react'

// Components
import Component from '../Component'
import CheckMark from '../icons/CheckMark'
import NoPatientDataIcon from '../icons/NoPatientDataIcon'

// Constants
import { NOTIFICATION_CATEGORIES } from '../../../constants/constants'

class NotificationHandler extends Component {
  render() {
    const {
      notification
    } = this.props

    if (
      notification == null ||
      notification.data == null ||
      notification.data.message == null ||
      notification.data.message.length === 0 ||
      typeof notification.data.message !== 'string'
    ) {
      return null
    }

    let isSuccess = false
    let isNoPatient = false
    if (notification.category === NOTIFICATION_CATEGORIES.SUCCESS) {
      isSuccess = true
    } else if (notification.category === NOTIFICATION_CATEGORIES.NO_PATIENT_DATA) {
      isNoPatient = true
    }

    return (
      <div className='notification-handler'>
        <div className='notification-container'>
          <div className='notification-content'>
            {isSuccess &&
              <CheckMark className='completed' />
            }

            {isNoPatient &&
              <NoPatientDataIcon />
            }

            <div className='notification-data'>
              <h1>{notification.data.message}</h1>

              {notification.data.details &&
                <div className='notification-details'>
                  {notification.data.details}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NotificationHandler

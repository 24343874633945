// Modules
import React from 'react'

const AcceptedIcon = props => {
  const st0 = {fill: 'none'}
  const st1 = {fill: 'none', stroke: '#7EBB24', 'strokeWidth': 0.6562}
  const st2 = {fill: '#FEFEFE'}
  const st3 = {fill: 'none', stroke: '#7EBB24', 'strokeWidth': 0.9844}

  return (
    <div className='multi-step-complete-icon'>
      <div>
        <svg x="0px" y="0px" width="33px" height="33px" viewBox="0 0 15 15">
          <g id="Group-4" transform="translate(0, 0.127974)">
            <path id="Fill-1" style={st0} d="M11,7.2v2.6v3.7c0,0.2-0.2,0.3-0.3,0.3H0.7c-0.2,0-0.3-0.2-0.3-0.3V0.6c0-0.2,0.2-0.3,0.3-0.3
              h9.9c0.2,0,0.3,0.2,0.3,0.3v1.7"/>
            <path id="Stroke-3" style={st1} d="M11,7.2v2.6v3.7c0,0.2-0.2,0.3-0.3,0.3H0.7c-0.2,0-0.3-0.2-0.3-0.3V0.6 c0-0.2,0.2-0.3,0.3-0.3h9.9c0.2,0,0.3,0.2,0.3,0.3v1.7"/>
          </g>
          <g id="Group-8" transform="translate(6.263752, 1.706921)">
            <polyline id="Fill-5" style={st2} points="0.7,2.9 2.8,5 7.4,0.4 2.8,5.1"/>
            <polyline id="Stroke-7" style={st3} points="0.7,2.9 2.8,5 7.4,0.4"/>
          </g>
        </svg>
      </div>
      {(props.text) && (
        <span>{props.text}</span>
      )}
    </div>
  )
}

export default AcceptedIcon

// Packages
import React from 'react'

// Components
import Component from '../../../common/Component'
import ConditionBody from './ConditionBody'
import RejectedIcon from '../../../common/icons/RejectedIcon'

class RejectedConditions extends Component {
  render() {
    const { conditions } = this.props
    let renderedConditions = conditions.map((condition, index) => (
      <ConditionBody
        condition={condition}
        conditionType='REJECTED'
        count={index + 1}
        key={index} />
    ))

    return (
      <section className='rejected-conditions'>
        <RejectedIcon text={'Rejected'} />
        {renderedConditions}
      </section>
    )
  }
}

export default RejectedConditions

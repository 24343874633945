// Redux helpers
import actionTypes from '../actionTypes'
import initialState from '../initialState'

const reducer = (state = initialState.authentication, action) => {
  switch (action.type) {
    case actionTypes.SET_SESSION_ID: {
      return {
        ...state,
        sessionId: action.sessionId
      }
    }

    case actionTypes.LOGIN_AND_GET_PATIENT:
      switch (action.status) {
        case 'success':
          return {
            ...state,
            hasHCC: action.applications.indexOf('hcc') !== -1,
            hasPatientSummary: action.applications.indexOf('patient summary') !== -1,
            loggedIn: true,
            loggingIn: false,
            token: action.token
          }

        case 'error':
          return {
            ...state,
            hasHCC: false,
            loggedIn: false,
            loggingIn: false
          }

        default:
          return {
            ...state,
            loggedIn: false,
            loggingIn: true
          }
      }

    case actionTypes.LOGIN_ENCRYPTED_GET:
      switch (action.status) {
        case 'success':
          return {
            ...state,
            hasHCC: true,
            loggedIn: true,
            loggingIn: false,
            token: action.token
          }

        case 'error':
          return {
            ...state,
            hasHCC: false,
            loggedIn: false,
            loggingIn: false
          }

        default:
          return {
            ...state,
            loggedIn: false,
            loggingIn: true
          }
      }

    default:
      return state
  }
}

export default reducer
// Packages
import { every } from 'lodash'

// Constants
import {
  KNOWLEDGE_TYPES,
  SINGLE_STEP_TYPES,
} from '../../../constants/constants'

////////////////////////////////////////////////////////////////////////////////////////////////////
// SINGLE-STEP CONDITIONS - HELPER FUNCTIONS
//
// Singleton namespace for similarly named functions between single-step and multi-step workflows.
////////////////////////////////////////////////////////////////////////////////////////////////////

export const singleStep = {
  // Returns true iff this documented condition is allowed to be completed
  canSaveCondition: function(condition, ci) {
    if (condition == null || condition.optionSelected == null) {
      return false
    }

    let noteText = condition.optionSelected[SINGLE_STEP_TYPES.NOTE] || ''

    if (!ci) {
        return ((condition.requiresNote && noteText.length > 0)
                    ||
                    (!condition.requiresNote))
    } else {
        return (
            (condition.providerKnowledge !== null &&
                ((condition.providerKnowledge === 'REJECTED' && condition.reasonOption) ||
                (condition.providerKnowledge === 'ACCEPTED' && condition.requiresNote && noteText.length > 0))))
    }
  },

  // Returns true iff the SubmitForReview action button can be clicked. Depends on the allRequired
  // flag and the condition.completed/condition.canSave values.
  canSubmitForReview: function(state, conditions) {
    if (state.allRequired) {
      // If an incomplete condition is found, then return false
      let allCompleted = false

      every(conditions, condition => {
        if (condition.completed) {
          allCompleted = true
          return true // check the next condition.completed
        } else {
          allCompleted = false
          return false // found an incomplete condition, break loop
        }
      })

      return allCompleted
    } else {
      // If one completed condition is found, then return true
      let someCompleted = false

      every(conditions, condition => {
        if (condition.completed) {
          someCompleted = true
          return false // found it, break loop
        } else {
          return true // try again
        }
      })

      return someCompleted
    }
  },

  // Returns a string of the styling classes used for Single-Step conditions.
  getStylingClasses: function(condition) {
    if (condition == null) {
      return ''
    }

    // Ensure lowercase type
    const type = condition.type.toLowerCase()

    // Add these classes to change the color of the options depending on the kind of knowledge the
    // user has about this condition.
    if (type === 'documented') {
      return type
    }

    if (condition.optionSelected == null) {
      return ''
    }

    if (
      condition.type === 'suspected' &&
      condition.optionSelected != null &&
      condition.optionSelected[SINGLE_STEP_TYPES.KNOWLEDGE] != null
    ) {
      let knowledgeType = condition.optionSelected[SINGLE_STEP_TYPES.KNOWLEDGE].id

      if (
        knowledgeType === KNOWLEDGE_TYPES.KNOWN ||
        knowledgeType === KNOWLEDGE_TYPES.UNKNOWN ||
        knowledgeType === KNOWLEDGE_TYPES.UNCERTAIN
      ) {
        return knowledgeType.toLowerCase()
      }
    }
  },

  // Returns true iff this step has a save button visible
  canShowSave: function(step) {
    return (step.name === SINGLE_STEP_TYPES.NOTE || step.name === SINGLE_STEP_TYPES.REJECT)
  },

  // Attach the necessary UI data to each condition object for the SingleStepWorkflow conditions.
  setConditionUI: function(conditions) {
    // TODO: Need to handle the case where a condition has been completed already. For example, if an
    //       option is selected and that condition's workflow was totally completed, it should have
    //       the completed icon, and all of its option UI should reflect the previous choices.
    return conditions.map(condition => {
        let type = conditions[0].type.toLowerCase()
        if (conditions[0].type === "CLINICAL_INFERENCE") {
            type = "clinicalinference"
        }
      let optionSelected = {}
      let updatedSteps = {
        ...condition.workflow.steps
      }

      // Use every to loop through steps, since it's an object (not an array)
      every(updatedSteps, step => {
        // Add this step to the optionSelected object so we can add choices to it on option select
        optionSelected[step.name] = null

        // Add a UI field to the step object
        step.showSaveButton = singleStep.canShowSave(step)

        // Set the option.filtered flag for the Diagnosis step (if step is present)
        if (step.name === SINGLE_STEP_TYPES.DIAGNOSIS) {
          step.searchText = ''
          if (!step.options) {
            step.options = [
                {
                    id: '5351',
                    description: 'TestId1 - This is the first test condition',
                    value: 'This is the first test condition',
                    requiresNote: false,
                    nextStep: null,
                    isVisible: true
                },
                {
                    id: '907154',
                    description: 'TestId2 - This is the second test condition',
                    value: 'This is the second test condition',
                    requiresNote: false,
                    nextStep: null,
                    isVisible: true
                },
                {
                    id: '283225',
                    description: 'TestId3 - This is the third test condition',
                    value: 'This is the third test condition',
                    requiresNote: false,
                    nextStep: null,
                    isVisible: true
                }
            ]
          }
          step.options.forEach(option => {
            option.isVisible = true
          })
        }

        return true
      })

      return {
        ...condition,
        type: type,
        optionSelected: optionSelected,
        requiresNote: false,
        canSave: singleStep.canSaveCondition(condition),
        stylingClasses: singleStep.getStylingClasses(condition),
        currentStep: {
          ...updatedSteps[0]
        },
        workflow: {
          ...condition.workflow,
          steps: updatedSteps
        },

        // Indexes are tracked for reference in the path array
        currentStepIndex: 0,

        // Null because user cannot go earlier than the first step
        prevStepIndex: null,

        // Null because next step depends on the option selected in the current step
        nextStepIndex: null,

        // Path is a stack (array) of the step indexes that the user has been shown (in order)
        path: [0]
      }
    })
  },

  // Given a search text string and an array of diagnosis options, return an array of diagnosis
  // options which contain the search text somewhere in their option.description field.
  filterDiagnoses: function(text, options) {
    return options.map(option => {
      const description = option.description.toLowerCase()
      const searchText = text.toLowerCase()
      let returnOption = {
        ...option
      }

      if ( text == null || text.length === 0 || description.indexOf(searchText) !== -1) {
        returnOption.isVisible = true
      } else {
        returnOption.isVisible = false
      }

      return returnOption
    })
  },

  // Returns true iff this condition has selected a status, and that status requires entering a note
  doesConditionRequireNote: function(condition) {
      let conditionStatus = condition.optionSelected[SINGLE_STEP_TYPES.STATUS]
      let providerKnowledge = condition.optionSelected[SINGLE_STEP_TYPES.INITIAL]

      if (conditionStatus != null && conditionStatus.requiresNote) {
          return true
      } else if (providerKnowledge != null && providerKnowledge.requiresNote) {
          return true
      } else {
        return false
      }
  },

  // For single step conditions, we need to update specific fields in the condition object that are
  // not present in multi-step conditions. Returns an updated copy of the condition object.
  updateUI: function(condition) {
    let updatedStep = {
      ...condition.currentStep
    }

    // Filter the diagnosis step options if that is the current step
    if (condition.currentStep.name === SINGLE_STEP_TYPES.DIAGNOSIS) {
      updatedStep.options = singleStep.filterDiagnoses(
        condition.currentStep.searchText,
        condition.currentStep.options
      )
      updatedStep.searchText = condition.currentStep.searchText || ''
    }

    // Update the currentStep in the steps object
    let updatedWorkflow = {
      ...condition.workflow,
      steps: {
        ...condition.workflow.steps,
        [condition.currentStep.id]: updatedStep
      }
    }

    return {
      ...condition,
      requiresNote: singleStep.doesConditionRequireNote(condition),
      stylingClasses: singleStep.getStylingClasses(condition),
      currentStep: updatedStep,
      workflow: updatedWorkflow
    }
  }
}

import actionTypes from '../actionTypes'

// Action creator for fetching the supporting evidence document for the active condition
const setColumns = (columnCount) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_COLUMNS,
      columns: columnCount
    })
  }
}

export { setColumns }

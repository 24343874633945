import React from 'react'

import CollapsableHeader from './CollapsableHeader'

export default class Collapsable extends React.Component {
  /***************************************************************************\
    Private Methods
  \***************************************************************************/

  _bindMethods (methods) {
    methods.forEach(method => (this[method] = this[method].bind(this)))
  }

  _handleChildren (children) {
    let content = []
    let header = false

    if (children == null || children.length === 0) {
      return {}
    } else if (!Array.isArray(children)) {
      children = [children]
    }

    children.forEach(child => {
      if (child != null && child.type === CollapsableHeader) {
        header = React.cloneElement(child, {
          onClick: this._onClick,
        })
      } else {
        content.push(child)
      }
    })

    if (!header) {
      header = (
        <CollapsableHeader onClick={this.onClick}>Details...</CollapsableHeader>
      )
    }

    return {
      content,
      header,
    }
  }

  _onClick (event) {
    let { open } = this.state
    let isOpening = !open

    this.setState({
      open: isOpening,
    })

    if (this.props.onClick) {
      this.props.onClick(this, isOpening)
    }
  }

  /***************************************************************************\
    Public Methods
  \***************************************************************************/

  UNSAFE_componentWillReceiveProps (nextProps) {
    let isOpen = this.props.open || this.state.open
    if (nextProps.open !== isOpen) {
      this.setState({
        open: nextProps.open,
      })
    }
  }

  constructor (props) {
    super(props)

    this._bindMethods(['_onClick'])

    this.state = {
      open: props.open,
    }
  }

  render () {
    const {
      open
    } = this.state

    const {
      children,
      className
    } = this.props

    let {
      content,
      header
    } = this._handleChildren(children)

    let openClassNames = open ? 'open' : 'closed'

    let classNames = 'collapsable ' + openClassNames

    if (className != null && className.length > 0) {
      classNames = className + ' ' + classNames
    }

    return (
      <div className={classNames}>
        {header}

        <div className="content">
          {content}
        </div>
      </div>
    )
  }
}

// Modules
import React from 'react'

const DocumentSeparatelyIcon = props => {

  let textClasses = null
  if (props.textColorSameAsIcon) {
    textClasses = 'colored-text'
  }

  let widthFactor = 0.75
  let viewBox = '0 0 14 15'
  if (props.bold) {
    widthFactor = 1.5
    viewBox = '0 -1 14 16'
  }

  return (
    <div className='multi-step-complete-icon'>
      <div>
        <svg width='14px' height='15px' viewBox={viewBox}>
          <g fill='none'>
            <g transform='translate(0.000000, 0.127974)'>
              <path d='M11.0177825,7.15847368 L11.0177825,9.74084211 L11.0177825,13.4718947 C11.0177825,13.6613684 10.8651036,13.8161053 10.676408,13.8161053 L0.732702346,13.8161053 C0.544789797,13.8161053 0.391327883,13.6613684 0.391327883,13.4718947 L0.391327883,0.611368421 C0.391327883,0.421105263 0.544789797,0.267157895 0.732702346,0.267157895 L10.676408,0.267157895 C10.8651036,0.267157895 11.0177825,0.421105263 11.0177825,0.611368421 L11.0177825,2.30636842'></path>
              <path d='M11.0177825,9.74084211 L11.0177825,13.4718947 C11.0177825,13.6613684 10.8651036,13.8161053 10.676408,13.8161053 L0.732702346,13.8161053 C0.544789797,13.8161053 0.391327883,13.6613684 0.391327883,13.4718947 L0.391327883,0.611368421 C0.391327883,0.421105263 0.544789797,0.267157895 0.732702346,0.267157895 L10.676408,0.267157895 C10.8651036,0.267157895 11.0177825,0.421105263 11.0177825,0.611368421 L11.0177825,2.30636842' stroke='#8BB73F' strokeWidth={widthFactor}></path>
            </g>
            <g transform='translate(8.500000, 3.750000)' stroke='#8BB73F'>
              <circle strokeWidth={widthFactor} cx='2.5' cy='2.5' r='2.5'></circle>
              <polyline strokeWidth={widthFactor} points='2.5 1.25 2.5 1.44260406 2.5 2.62747026 3.55262756 3.55167389'></polyline>
            </g>
          </g>
        </svg>
      </div>
      {(props.text) && (
        <span className={textClasses}>
          {props.text}
        </span>
      )}
    </div>
  )
}

export default DocumentSeparatelyIcon

import actionTypes from '../actionTypes'
import API_ROOT from '../apiService'
import store from '../store'
import { saveCondition } from './conditions'
import { addError } from '../helpers/notificationHelper'

// Need to save the active condition, then use the new submission array for submitForReview
export const saveConditionAndSubmitForReview = (activeCondition) => {
  return dispatch => {
    saveCondition(activeCondition, false, submitForReview)(dispatch)
  }
}

// Submit the condition data to the API again, and present the ReviewConditions screen to the user
export const submitForReview = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.SUBMIT_FOR_REVIEW
    })

    API_ROOT.request({
      method: 'post',
      url: '/hcc/conditions?action=review',
      headers: {
        Authorization: `Bearer ${store.getState().authentication.token}`,
      },
      data: {
        conditions: store.getState().submission
      },
    }).then(response => {
      dispatch({
        type: actionTypes.SUBMIT_FOR_REVIEW,
        status: 'success',
        data: response.data.data,
      })
    }).catch(error => {
      dispatch({
        type: actionTypes.SUBMIT_FOR_REVIEW,
        status: 'error'
      })

      addError(dispatch, error)
    })
  }
}

// Final submit action for the condition data
export const signAndSubmit = () => {
  return dispatch => {
    const state = store.getState().reviewSubmission

    dispatch({
      type: actionTypes.SIGN_AND_SUBMIT
    })

    if (shouldHitLockError(state)) {
      testLockError(dispatch, state)
    } else {
      // Otherwise, call the regular submission endpoint
      API_ROOT.request({
        method: 'post',
        url: '/hcc/conditions/approve?attempt=' + state.lockedError.lockedCount,
        headers: {
          Authorization: `Bearer ${store.getState().authentication.token}`,
        },
        data: {
          reportId: state.reportId
        }
      }).then(response => {
        dispatch({
          type: actionTypes.SIGN_AND_SUBMIT,
          keepReviewOpen: store.getState().env.columns === 2,
          status: 'success'
        })

        if (window.parent) {
          window.parent.postMessage(true, '*')
        }
      }).catch(error => {
        dispatch({
          type: actionTypes.SIGN_AND_SUBMIT,
          status: 'error'
        })

        addError(dispatch, error)
      })
    }
  }
}

// Should we use the fake endpoint which always returns the lock error, or the real endpoint?
function shouldHitLockError(state) {
  return (
    // Fake the locked error only until the succeedCount is met, then use the real submit endpoint
    state.lockedError.lockedCount < state.lockedError.succeedAfterCount &&

    // If testLockError query param flag is set, then we should call the mock error endpoint
    window.location.search.indexOf('testLockError=true') !== -1
  )
}

// This mocks a "Visit Diagnosis is Locked" error response, instead of hitting the real endpoint
function testLockError(dispatch, state) {
  API_ROOT.request({
    method: 'get',
    url: '/hcc/lockederror?attempt=' + state.lockedError.lockedCount,
    headers: {
      Authorization: `Bearer ${store.getState().authentication.token}`,
    }
  }).then(response => {
    dispatch({
      type: actionTypes.SIGN_AND_SUBMIT,
      status: 'error',
      testLockError: testLockError
    })

  }).catch(error => {
    // Delay the response to mock the error
    // TODO: Remove this?
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    sleep(1000).then(() => {
      // The error object was different, so set up a real error message here
      dispatch({
        type: actionTypes.SIGN_AND_SUBMIT,
        status: 'error',
        testLockError: testLockError
      })

      addError(dispatch, error)
    })
  })
}

// It has been determined that the Review Conditions screen should be closed.
export const closeReview = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLOSE_REVIEW
    })
  }
}

export const closeSubmissionModal = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.CLOSE_SUBMISSION_MODAL
    })
  }
}

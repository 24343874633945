// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'

// Action Creators
import { changeStep } from '../../../store/actions/conditions'

// Constants
import { ARROW_DIRECTIONS } from '../../../constants/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

class StepChanger extends Component {
  render() {
    const {
      direction,
      isVisible,
      condition,
      changeStep,
    } = this.props

    if (direction == null || condition == null) {
      return null
    }

    let classNames = 'step-changer'

    // Component is always present, but the arrow may not be clickable or visible
    if (!isVisible) {
      classNames += ' hidden'
    }

    // The arrow is set based on the className, and generated by pure CSS
    return (
      <div className={classNames}>
        {(direction === ARROW_DIRECTIONS.PREV) &&
          <span onClick={() => isVisible && changeStep(condition, direction)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
        }

        {(direction === ARROW_DIRECTIONS.NEXT) &&
          <span onClick={() => isVisible && changeStep(condition, direction)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        }
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeStep: changeStep
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(StepChanger)

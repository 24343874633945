import actionTypes from '../actionTypes'
import API_ROOT from '../apiService'
import store from '../store'

// Action creator for fetching the supporting evidence document for the active condition
const getEvidenceDocument = (conditionId) => {
  return dispatch => {
    dispatch({
      type: actionTypes.GET_EVIDENCE_DOCUMENT
    })

    // TODO: This is a fake id - need to replace once a real evidence document is available.
    conditionId = 123

    API_ROOT.request({
      method: 'get',
      url: `/hcc/condition/supporting-evidence/document/${conditionId}`,
      headers: {
        Authorization: `Bearer ${store.getState().authentication.token}`,
      },
      responseType: 'arraybuffer',
    }).then(response => {
      let blob = new Blob([response.data], {type: 'application/pdf'});
      let pdfUrl = window.URL.createObjectURL(blob)

      dispatch({
        type: actionTypes.GET_EVIDENCE_DOCUMENT,
        status: 'success',
        data: pdfUrl
      })
    }).catch(error => {
      console.error(error)

      let errorData = null

      if (error.response && error.response.data) {
        // A request was made, but the server responded with a status code not in the range of 2xx
        errorData = error.response.data.error
      } else if (error.message) {
        // A request may have been made, but no response was received
        errorData = error.message
      }

      // Adds an error message only inside of the secondary column, not the whole page
      dispatch({
        type: actionTypes.GET_EVIDENCE_DOCUMENT,
        status: 'error',
        error: errorData
      })
    })
  }
}

export default getEvidenceDocument

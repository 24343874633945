// Packages
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'

// Components
import Component from '../../common/Component'
import CheckMark from '../../common/icons/CheckMark'
import Button from '../../common/Button/Button'

class Submitted extends Component {
  // Returns an integer of how many of these conditions have the providerKnowledge 'ACCEPTED'
  // (NB: this.props.acceptedConditions may include 'ACCEPTED' and 'ACCEPTED_DOCUMENT_SEPARATELY')
  countAcceptedDiagnoses(conditions) {
    let count = 0

    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].providerKnowledge === 'ACCEPTED') {
        count++
      }
    }

    return count
  }

  getNoteLines(allAcceptedConditions) {
    let diagnosisCount = 0
    let acceptedCount = 0
    let diagnosisText = null
    let noteLines = []

    if (allAcceptedConditions) {
      diagnosisCount = allAcceptedConditions.length || 0
      acceptedCount = this.countAcceptedDiagnoses(allAcceptedConditions)
    }

    if (diagnosisCount > 0) {
      diagnosisText = (diagnosisCount === 1) ?
        diagnosisCount + ' diagnosis' :
        diagnosisCount + ' diagnoses'

      let line1 = (
        <div key='1'>
          Added {diagnosisText} to the visit diagnoses list and patient's problem list.
        </div>
      )

      noteLines.push(line1)

      let line2 = null
      if (acceptedCount > 0) {
        line2 = (
          <div key='2'>
            A note was added to the patient's encounter.
          </div>
        )

        noteLines.push(line2)
      }
    } else {
      noteLines.push(
        <div key='1'>
          All conditions were rejected. No further action was taken on the patient's encounter.
        </div>
      )
    }

    return noteLines
  }

  refreshApp() {
    window.location.reload()
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'countAcceptedDiagnoses',
      'getNoteLines',
      'refreshApp'
    ])
  }

  render() {
    const {
      allAcceptedConditions,
      allCompleted
    } = this.props

    const noteLines = this.getNoteLines(allAcceptedConditions)

    return (
      <section className='submitted'>
        <div className='diagnoses-submitted'>
          <div className='success-area'>
            <div className='submitted-check-container'>
              <CheckMark className='completed'/>
            </div>
            <h1>
              Success!
            </h1>

            <h3>
              {noteLines}
            </h3>
          </div>

          {!allCompleted &&
            <div className='refresh-area'>
              <div>
                Some conditions were not completed. Click below to add more documentation.
              </div>

              <Button
                onClick={this.refreshApp}
              >
                <FontAwesomeIcon icon={faChevronCircleLeft} className='prefix-icon' />
                More Conditions
              </Button>
            </div>
          }
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  allAcceptedConditions: state.reviewSubmission.acceptedConditions,
  allCompleted: state.conditions.allCompleted
})

export default connect(mapStateToProps, null)(Submitted)

import React from 'react'
import Component from '../../common/Component'
import AcceptedClaimIcon from '../../common/icons/AcceptedClaimIcon'
import ClinicalNoteIcon from '../../common/icons/ClinicalNoteIcon'
import ProblemListIcon from '../../common/icons/ProblemListIcon'

class EvidenceHeader extends Component {
  // Given a date timestamp, return a string to display to the user, representing the most
  // accurate date information that can be determined from the dateString.
  formatDate(dateString) {
    /*
      If dateString, an ISO-8601 datetime string, contains "T00:00:00" as its timestamp, then we can assume that this time value is contrived and not a relevant piece of information (see CONV-1251 for business reasons explaining this assumption). In this case, the time (and time zone) will be discarded, and only the string of the date (mm/dd/yyyy) will be displayed to the user.

      However, if a valid timestamp is included (and not determined to be irrelevant, as above), then that datetime will be interpretted as a Date, and displayed to the user in their local timezone.

      Examples:
        // Full datetime, with timezone => Display datetime in local time zone
        dateString = '2018-02-01T12:40:30Z'
        dateString = '2018-08-01T00:01:00-04:00'

        // No relevant time indicated => display mm-dd-yyyy as given, no local time zone change!
        dateString = '2018-02-01T00:00:00Z'
        dateString = '2018-02-01'
    */

    // Regex: yyyy-mm-dd first, then an optional T for timestamp hh:mm:ss
    const re = /^(\d{4})-(\d{2})-(\d{2})(T(\d{2}):(\d{2}):(\d{2}).*)?/g
    const result = re.exec(dateString)

    // If the regex fails, do not display a date
    if (result == null) {
      return null
    }

    let year = result[1] || null
    let month = result[2] || null
    let day = result[3] || null
    const hour = result[5] || null
    const minute = result[6] || null
    const second = result[7] || null

    // Use the timestamp in determining the date if they are not all "00"
    if (
      (hour != null && hour !== '00') ||
      (minute != null && minute !== '00') ||
      (second != null && second !== '00')
    ) {
      // Interpret this date in the local time zone, which MAY change the date!
      let date = new Date(dateString)
      if (date == null) {
        return null
      }

      day = date.getDate().toString()
      month = (date.getMonth() + 1).toString()
      year = date.getFullYear().toString()
    }

    return month + '/' + day + '/' + year
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'formatDate'
    ])
  }

  render() {
    const {
      dateOfService,
      docLink,
      author,
      location,
      onViewDocument
    } = this.props

    let {
      evidenceName
    } = this.props

    let formattedDate = this.formatDate(dateOfService)

    let icon = <AcceptedClaimIcon />

    // Ensure the displayed name is a normal string, and select the appropriate icon
    if (evidenceName === 'Clinical Note' || evidenceName === 'CLINICAL_RECORD') {
      evidenceName = 'Clinical Note'
      icon = <ClinicalNoteIcon />
    } else if (evidenceName === 'Problem List' || evidenceName === 'PROBLEM_LIST') {
      evidenceName = 'Problem List'
      icon = <ProblemListIcon />
    } else if (evidenceName === 'HP Campaign' || evidenceName === 'HP_CAMPAIGN' ) {
      evidenceName = 'HP Campaign'
    } else if (evidenceName === 'HP Campaign Suspect' || evidenceName === 'HP_CAMPAIGN_SUSPECT') {
      evidenceName = 'HP Campaign Suspect'
    } else if (evidenceName === 'HP Source Data' || evidenceName === 'HP_SOURCE_DATA') {
      evidenceName = 'HP Source Data'
    } else {
      evidenceName = 'Accepted Claim'
    }

    const hasAuthor = (author && author != null && author.length > 0)
    const hasLocation = (location && location != null && location.length > 0)

    return (
      <div className='evidence-header'>
        <div className='evidence-icon'>
          {icon}
        </div>

        <div className='evidence-details'>
          <div className='evidence-name'>{evidenceName}</div>

          {formattedDate &&
            <div className='evidence-date'>{formattedDate}</div>
          }

          {/* This is disabled for now, since the document viewer is not working properly. */}
          {false && docLink &&
            <span
              className='view-doc-link'
              onClick={onViewDocument.bind(this, formattedDate)} >
              View note
            </span>
          }

          <div className='evidence-provider'>
            {hasAuthor && !hasLocation &&
              <span>{author}</span>
            }
            {!hasAuthor && hasLocation &&
              <span>{location}</span>
            }
            {hasAuthor && hasLocation &&
              <span>{author}, {location}</span>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default EvidenceHeader

import actionTypes from '../actionTypes'

// Constants
import {
  NOTIFICATION_CATEGORIES,
  RETRYABLE_ERROR_CODES,
  ERROR_CODE_TO_RETRY_TEXT
} from '../../constants/constants'

// Handles displaying a message to the UI which prevents the user from continuing.
export function addNotification(dispatch, message, details, category) {
  if (category == null) {
    category = NOTIFICATION_CATEGORIES.INFO
  }

  if (details && details.length === 0) {
    details = null
  }

  dispatch({
    type: actionTypes.ADD_NOTIFICATION,
    category: category,
    data: {
      message: message,
      details: details
    }
  })
}

// Handles displaying an error message to the UI, and ensuring that no bad message is rendered.
export function addError(dispatch, error) {
  let correlationId = null
  let isLockedError = false

  if (error.response) {
    if (error.response.headers && error.response.headers['x-moxe-correlation-id']) {
      correlationId = error.response.headers['x-moxe-correlation-id']
    }

    if (error.response.data && error.response.data.error) {
      // A request was made, but the server responded with a status code not in the range of 2xx
      error = error.response.data.error
    }
  } else {
    // This is a client error - change the message to hide error details from users
    error = {
      code: 'CLIENT_ERROR',
      message: 'An error has occurred. Please contact your support team to resolve this issue.'
    }
  }

  // Build the retry object if the error code is in the list of retry-able errors
  if (error && error.code && RETRYABLE_ERROR_CODES.indexOf(error.code) !== -1) {
    error.retryText = getRetryText(error.code)

    // Need to filter specific errors and add more data, from parsed error message
    if (
      error.code === 'COULD_NOT_LOCK_VISIT_DIAGNOSES' ||
      error.code === 'COULD_NOT_LOCK_PROBLEM_LIST'
    ) {
      isLockedError = true

      // Attempt to split this error message into various fields. Matches will only succeed if
      // the format is exactly as expected. Otherwise, throw out the whole match.
      const re = /^(.*) by user (.*) since (.*) on workstation (.*).Department: (.*)Telephone: (\d{3}-\d{3}-\d{4})$/g
      const result = re.exec(error.message)

      if (result) {
        const message = result[1] == null ? null : result[1]
        const lockedBy = result[2] == null ? null : result[2]
        const since = result[3] == null ? null : result[3]
        const workstation = result[4] == null ? null : result[4]
        const department = result[5] == null ? null : result[5]
        const phoneNumber = result[6] == null ? null : result[6]

        error.message = message + '.'
        error.details = {
          'Locked by': lockedBy,
          'Since': since,
          'Workstation': workstation,
          'Department': department,
          'Phone Number': phoneNumber
        }
      }
    }
  }

  dispatch({
    type: actionTypes.ADD_NOTIFICATION,
    category: NOTIFICATION_CATEGORIES.ERROR,
    data: error,
    correlationId: correlationId,
    isLockedError: isLockedError,
  })
}

// Given an errorCode string, returns a string describing what action to take on retry. This
// text is used as the text for the retry button on an error message.
function getRetryText(errorCode) {
  return ERROR_CODE_TO_RETRY_TEXT[errorCode] || 'Retry Submission'
}

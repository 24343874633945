// Packages
import React from 'react'
import { connect } from 'react-redux'

// Components
import Component from '../../common/Component'

class ProgressBar extends Component {
  render() {
    const {
      recommendedConditions,
      ciConditions
    } = this.props

    let progressConditions = recommendedConditions;
    if (ciConditions.length > 0) {
        progressConditions = ciConditions;
    }
    let completedCount = 0
    let remainingCount = 0

    for (let i = 0; i < progressConditions.length; i++) {
      if (progressConditions[i].completed || progressConditions[i].canSave) {
        completedCount++
      } else {
        remainingCount++
      }
    }

    // Set the bar width as a proportion of their counts compared to the whole
    let completedBarWidth = (completedCount / progressConditions.length * 100) + '%'
    let remainingBarWidth = (remainingCount / progressConditions.length * 100) + '%'

    return (
      <div className='progress-bar'>
        <div className='meter-container'>
          <div
            className='completed-bar'
            style={{
              width: completedBarWidth
            }}
          >
          </div>
          <div
            className='remaining-bar'
            style={{
              width: remainingBarWidth
            }}
          >
          </div>
        </div>

        <div className='description-container'>
          {completedCount} of {progressConditions.length}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  recommendedConditions: state.conditions.recommended.list,
  ciConditions: state.conditions.clinicalinference.list,
  recommendedWorkflow: state.conditions.recommendedWorkflow,
  canSubmitForReview: state.conditions.canSubmitForReview,
  isSubmittingForReview: state.reviewSubmission.isSubmittingForReview
})

export default connect(mapStateToProps, null)(ProgressBar)
// Packages
import { find } from 'lodash'

import actionTypes from '../actionTypes'
import API_ROOT from '../apiService'
import store from '../store'

import { addNotification, addError } from '../helpers/notificationHelper'

// Constants
import { NOTIFICATION_CATEGORIES } from '../../constants/constants'

function convertConditionsToArray (conditions) {
  return Object.keys(conditions).map(key => conditions[key])
}

function filterConditionsByType (conditions, type) {
  return convertConditionsToArray(conditions).filter(condition => condition.type === type)
}

// Returns true iff there is no valid condition data
function hasNoConditionData(recommended, documented, suspected, ci) {
  return (
    (recommended == null || recommended.length === 0) &&
    (documented == null || documented.length === 0) &&
    (suspected == null || suspected.length === 0) &&
    (ci == null || ci.length === 0)
  )
}

// Add fields to the condition data structure that assist in displaying the UI.
function populateConditionUI(conditions) {
  return conditions.map(condition => {
    let completed = false
    let statuses = []
    let reasonStep = null
    let reasonOption = null

    let initialOption = find(condition.workflow.steps[0].options, option => {
      return option.id === condition.providerKnowledge
    })

    if (initialOption != null) {
      if (initialOption.id === 'ACCEPTED_DOCUMENT_SEPARATELY') {
        completed = true
      } else {
        reasonStep = condition.workflow.steps[parseInt(initialOption.nextStep, 10)]

        // Try to determine the condition status (reasonOption) based on the text in statuses
        if (condition.statuses && condition.statuses.length) {
          reasonOption = find(reasonStep.options, option => {
            return option.description === condition.statuses[0]
          })
        }

        // If that didn't work, then we can try to determine reasonOption based on the note text
        if (reasonOption == null && condition.note && condition.note.length) {
          if (initialOption.id === 'ACCEPTED') {
            // No reasonOption is required if a note was entered (for the Accepted reason step)
            completed = true
          } else if (initialOption.id === 'REJECTED') {
            // Rejected condition status is saved in the note. See if it matches any of the options
            reasonOption = find(reasonStep.options, option => {
              return option.description === condition.note
            })

            // If it doesn't, just set it to the 'Other' option
            if (reasonOption == null) {
              reasonOption = reasonStep.options[reasonStep.options.length - 1]
            }
          }
        }
      }

      // If reasonOption was found at some point, then mark it as completed
      if (reasonOption != null) {
        completed = true
        statuses = [`${reasonOption.id}`] // We need to use the id, not the description text
      }
    }

    return {
      ...condition,
      initialOption: initialOption,
      reasonStep: reasonStep,
      reasonOption: reasonOption,
      completed: completed,
      statuses: statuses,
      canSave: completed // Is the saveCondition button active in ReasonStep?
    }
  })
}

export const getHcc = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.HCC_DATA_GET
    })

    API_ROOT.request({
      url: '/hcc/conditions?scope=workflow',
      headers: {
        Authorization: `Bearer ${store.getState().authentication.token}`,
      },
    }).then(response => {
      let recommendedConditions = filterConditionsByType(response.data.data, 'RECOMMENDED')
      let documentedConditions = filterConditionsByType(response.data.data, 'DOCUMENTED')
      let suspectedConditions = filterConditionsByType(response.data.data, 'SUSPECTED')
      let ciConditions = filterConditionsByType(response.data.data, 'CLINICAL_INFERENCE')

        if (hasNoConditionData(
            recommendedConditions,
            documentedConditions,
            suspectedConditions,
            ciConditions
        )) {
        addNotification(
          dispatch,
          'You\'re all set! There are no conditions that need review.',
          null,
          NOTIFICATION_CATEGORIES.SUCCESS
        )

        // Still need to dispatch this to get rid of the Loading screen
        dispatch({
          type: actionTypes.HCC_DATA_GET,
          status: 'hasNoConditionData'
        })
      } else {
        dispatch({
          type: actionTypes.HCC_DATA_GET,
          status: 'success',
          recommended: populateConditionUI(recommendedConditions),
          documented: documentedConditions,
          suspected: suspectedConditions,
          clinicalinference: populateConditionUI(ciConditions),
          meta: response.data.meta,
        })
      }
    }).catch(error => {
      dispatch({
        type: actionTypes.HCC_DATA_GET,
        status: 'error',
      })

      addError(dispatch, error)
    })
  }
}

const initialState = {
  authentication: {
    sessionId: null,
    token: null,
    loggedIn: false,
    loggingIn: false,
    loggingOut: false,
    hasHCC: false,
    hasPatientSummary: false
  },

  env: {
    columns: 1,
    disclaimer: null,
  },

  patientSummary: {
    isLoading: true,
    memberInformation: {
      member: '',
      humanaId: '',
      DOB: '',
      gender: '',
      cityState: '',
      plan: '',
      participatingPrograms: '',
      policyEffective: '',
      provider: '',
      phone: '',
    },
    patientQuality: {},
    patientAdmission: {},
    prescription: {},
    healthConditionHistory: {},
    labResults: {},
  },

  conditions: {
    fetching: false,
    fetched: false,
    recommendedWorkflow: false,
    allRequired: false,
    allCompleted: false,
    canSubmitForReview: false,
    documented: {
      allCompleted: false,
      active: null,
      list: []
    },
    recommended: {
      allCompleted: false,
      active: null,
      list: []
    },
    suspected: {
      allCompleted: false,
      active: null,
      list: []
    },
    clinicalinference: {
      allCompleted: false,
      active: null,
      list: []
    }
  },

  evidenceDocument: {
    fetching: false,
    data: null,
    error: null
  },

  submission: [],

  reviewSubmission: {
    isSubmittingForReview: false,
    isReviewingConditions: false,
    isSubmissionModalOpen: false,
    acceptedConditions: null,
    rejectedConditions: null,
    reviewText: null,
    reportId: null,
    signing: false,
    signed: false,
    patient: {
      appointmentTime: null,
      id: null,
      memberId: null,
      givenName: null,
      surname: null,
      name: null,
      visitType: null,
      sex: null,
      dateOfBirth: null,
      applications: null
    },

    lockedError: {
      shouldRetry: true, // If submission fails, should a retry attempt be allowed?
      retryDelay: 3000,  // ms to wait before next submit call is made, after being triggered
      extraDescriptionDelay: 3000, // ms to wait before allowing user to do manual override

      lockedCount: 0,    // How many times we have hit the locked error
      maxRetryCount: 1, // If lockedCount >= maxRetryCount, shouldRetry will be set to false
      succeedAfterCount: 8 // When testing the lock error, which retry attempt should be successful?
    },
  },

  notification: {
    category: null,
    data: null,
    sessionId: null,
    correlationId: null
  }
}

export default initialState

// Packages
import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

// Components
import Component from '../Component'

// Action Creators
import {signAndSubmit} from '../../../store/actions/signAndSubmit'

class RetryableError extends Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        // When lockedError.isRetrying goes from true => false, trigger a retry attempt
        // Note that no retry will be called if shouldRetry is false OR if a retry attempt is in progress.
        if (
            nextProps.reviewSubmission.lockedError.shouldRetry &&
            !nextProps.reviewSubmission.signing &&
            this.state.retryTimeout == null
        ) {
            this.setState({
                showRetrySpinner: true,
                retryTimeout: setTimeout(this.props.signAndSubmit, nextProps.reviewSubmission.lockedError.retryDelay)
            })
        }

        // If we're in the middle of a submission, cancel any enqueued submission timeouts
        if (nextProps.reviewSubmission.signing) {
            if (this.state.retryTimeout) {
                clearTimeout(this.state.retryTimeout)
            }

            this.setState({
                retryTimeout: null
            })
        }

        // Retry either succeeded or failed. Either way, kill the retry loop.
        if (nextProps.reviewSubmission.signed || !nextProps.reviewSubmission.lockedError.shouldRetry) {
            if (this.state.retryTimeout) {
                clearTimeout(this.state.retryTimeout)
            }

            this.setState({
                retryTimeout: null,
                showRetrySpinner: false
            })
        }
    }

    constructor(props) {
        super(props)

        this.state = {
            areInstructionsOpen: false,
            showRetrySpinner: false,
            retryTimeout: null,
            showExtraDescription: false
        }

        // In a few seconds, allow the user to access the manual override steps. This will cancel the
        // retry loop, but allows the user more sense of control.
        setTimeout(() => {
            this.setState({
                showExtraDescription: true
            })
        }, props.reviewSubmission.lockedError.extraDescriptionDelay)

        // When lockedError.isRetrying goes from true => false, trigger a retry attempt if shouldRerty is also true
        if (props.reviewSubmission.lockedError.shouldRetry && !props.reviewSubmission.signing) {
            this.state.retryTimeout = setTimeout(props.signAndSubmit, props.reviewSubmission.lockedError.retryDelay)
            this.state.showRetrySpinner = false
        }
    }

    render() {
        const {
            errorData,
        } = this.props

        if (!errorData) {
            return null
        }

        if (errorData.code === 'COULD_NOT_LOCK_VISIT_DIAGNOSES'){
            return (
                <div className='notification-data'>
                    <h1>Adding documentation to patient chart.</h1>

                    {this.state.showExtraDescription &&
						<p align='center'>Your work has been saved and will be visible once the patient chart is closed. Click any tab to leave this screen.</p>
                    }
                </div>
            )
        } else {
            return (
                <div className='notification-data'>
                    <h1>Adding documentation to encounter</h1>

                    {this.state.showExtraDescription &&
                    <p>We’re currently experiencing connection issues, please continue to wait until all documentation
                        is added or retry in 2 minutes.</p>
                    }
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    reviewSubmission: state.reviewSubmission,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        signAndSubmit: signAndSubmit,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RetryableError)

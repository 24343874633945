// Packages
import React from 'react'

// Components
import Component from '../Component'
import Dialog from './Dialog'
import Checkmark from '../icons/CheckMark'

class SuccessDialog extends Component {
  render() {
    const {
      children,
      isOpen,
      onClose
    } = this.props

    return (
      <Dialog
        className='success-dialog'
        isModal={true}
        isOpen={isOpen}
        onClose={onClose}
        hideCloseButton={true}
      >
        <Checkmark className='completed' />
        {children}
      </Dialog>
    )
  }
}

export default SuccessDialog

// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'

// Action Creators
import { updateCondition } from '../../../store/actions/conditions'

class StatusStep extends Component {

  // Returns an object with the fields needed to maintain the condition's step state.  
  getNewStepFields(condition, option) {
    let newCurrentStep = condition.currentStep
    let newCurrentStepIndex = condition.currentStepIndex
    let newPrevStepIndex = condition.prevStepIndex
    let newNextStepIndex = condition.nextStepIndex
    let newPath = condition.path
    let checkStep = null

    // Change the step if a new option was selected
    if (option != null) {
      if (condition.currentStep.isSequential) {
        // The new currentStep should be the next step in the workflow
        checkStep = condition.workflow.steps[parseInt(condition.currentStepIndex, 10) + 1] || null
      } else if (option.nextStep != null) {
        // The new currentStep should be whichever one is specified in this option
        checkStep = condition.workflow.steps[option.nextStep] || null
      }

      if (checkStep != null) {
        // Go to the next step
        newCurrentStep = checkStep
        newCurrentStepIndex = checkStep.id
        newPrevStepIndex = condition.currentStepIndex.valueOf()
        newNextStepIndex = null

        // Add the new current step to the path
        let pathCopy = condition.path.slice(0)
        pathCopy.push(newCurrentStepIndex)
        newPath = pathCopy
      }
    }

    return {
      currentStep: newCurrentStep,
      path: newPath,
      currentStepIndex: newCurrentStepIndex,
      prevStepIndex: newPrevStepIndex,
      nextStepIndex: newNextStepIndex
    }
  }

  // Called when a reason option is selected (NB: not necessarily when it changes)
  onOptionSelect(option) {
    const condition = this.props.condition

    if (option == null || option.id == null) {
      console.error('Cannot select status option: option.id is null')
      return
    }

    let newNextStepIndex = condition.nextStepIndex
    let newPath = condition.path

    // Check if this is the a different option than before
    const previousOption = condition.optionSelected[condition.currentStep.name]
    if (previousOption != null && previousOption.description === option.description) {

      // Same option as before => deselect the option (does not change the step)
      option = null

      newNextStepIndex = null

      // Need to remove the next step from the path, if it's present
      if (condition.path[condition.path.length - 1] === condition.nextStepIndex) {
        newPath = condition.path.slice(0, condition.path.length - 2)
      }
    }

    let updatedCondition = {
      ...condition,
      optionSelected: {
        ...condition.optionSelected,
        [condition.currentStep.name]: option
      },
      nextStepIndex: newNextStepIndex,
      path: newPath
    }

    // Update the condition right away, so the selection shows up in the UI
    this.props.updateCondition(updatedCondition)

    updatedCondition = {
      ...updatedCondition,
      ...this.getNewStepFields(updatedCondition, option)
    }

    // If the step will change, add a small delay. Otherwise update immediately.
    if (updateCondition.currentStepIndex !== condition.currentStepIndex) {
      setTimeout(() => {
        this.props.updateCondition(updatedCondition)
      }, 750)
    } else {
      this.props.updateCondition(updatedCondition)
    }
  }

  // Returns the pill object for the given condition status object
  formatPill(condition, option) {
    const currentlySelected = condition.optionSelected[condition.currentStep.name]
    let pillClasses = 'pill ' + condition.stylingClasses

    // Set the active/selected option styling
    if (currentlySelected != null && currentlySelected.description === option.description) {
      pillClasses += ' pill-active'
    }

    return (
      <div
        key={option.id}
        className={pillClasses}
        onClick={() => this.onOptionSelect(option)}>
        {option.description}
      </div>
    )
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'formatPill',
      'onOptionSelect',
      'getNewStepFields'
    ])
  }

  render() {
    const {
      condition
    } = this.props

    if (condition == null || condition.currentStep == null) {
      return null
    }

    let conditionStatuses = []

    conditionStatuses = condition.currentStep.options.map(option => {
      if (option.description.toLowerCase() === 'other') {
        return (
          <div key='other' className='other-pill'>
            {this.formatPill(condition, option)}
          </div>
        )
      } else {
        return this.formatPill(condition, option)
      }
    })

    return (
      <form className='single-step single-step-status'>
        <header>
          <h2>
            {condition.currentStep.title}
          </h2>
        </header>

        <div className='status-selector'>
          {conditionStatuses}
        </div>
      </form>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateCondition: updateCondition
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(StatusStep)

// Modules
import React from 'react'

const RejectedIcon = (props) => {
  return (
    <div className='multi-step-complete-icon'>
      <div>
        <svg x="0px" y="0px" width="33px" height="33px" viewBox="0 0 23 23">
          <g id="Page-1" style={{fill: 'none'}}>
            <g id="Group-4" transform="translate(0, 0)">
              <path d="M17.3828118,11.4535579 L17.3828118,15.5853474 L17.3828118,21.5550316 C17.3828118,21.8581895 17.1419294,22.1057684 16.8442235,22.1057684 L1.15598824,22.1057684 C0.859517647,22.1057684 0.6174,21.8581895 0.6174,21.5550316 L0.6174,0.978189474 C0.6174,0.673768421 0.859517647,0.427452632 1.15598824,0.427452632 L16.8442235,0.427452632 C17.1419294,0.427452632 17.3828118,0.673768421 17.3828118,0.978189474 L17.3828118,3.69018947" id="Fill-1"></path>
              <path d="M17.3828118,11.4535579 L17.3828118,15.5853474 L17.3828118,21.5550316 C17.3828118,21.8581895 17.1419294,22.1057684 16.8442235,22.1057684 L1.15598824,22.1057684 C0.859517647,22.1057684 0.6174,21.8581895 0.6174,21.5550316 L0.6174,0.978189474 C0.6174,0.673768421 0.859517647,0.427452632 1.15598824,0.427452632 L16.8442235,0.427452632 C17.1419294,0.427452632 17.3828118,0.673768421 17.3828118,0.978189474 L17.3828118,3.69018947" id="Stroke-3" stroke="#AE2525" strokeWidth="1.25"></path>
            </g>
            <g id="Group-8" transform="translate(13.588235, 3.994232)">
              <path d="M0.0646058824,0.0586105263 L7.31948824,7.47713684" id="Fill-5" fill="#FEFEFE"></path>
              <path d="M0.0646058824,0.0586105263 L7.31948824,7.47713684" id="Stroke-7" stroke="#AE2525" strokeWidth="1.875"></path>
            </g>
            <g id="Group-12" transform="translate(13.588235, 3.994232)">
              <path d="M7.31899412,0.0586105263 L0.0641117647,7.47713684" id="Fill-9" fill="#FEFEFE"></path>
              <path d="M7.31899412,0.0586105263 L0.0641117647,7.47713684" id="Stroke-11" stroke="#AE2525" strokeWidth="1.875"></path>
            </g>
          </g>
        </svg>
      </div>
      {(props.text) && (
        <span>{props.text}</span>
      )}
    </div>
  )
}

export default RejectedIcon

// Packages
import axios from 'axios'
import actionTypes from './actionTypes'
import store from './store'

// Session helper
import getSessionId from '../session'

// Determine API endpoint to hit based on environment
let baseURL = null
let useCreds = true
let timeout = process.env.REACT_APP_TIMEOUT || 40000

if (process.env.REACT_APP_ENV === 'dummy') {
  useCreds = false
  baseURL = 'http://localhost:8080/api/v1'
}

if (process.env.REACT_APP_ENV === 'local') {
  baseURL = 'http://localhost:8080/v1'
}

if (process.env.REACT_APP_ENV === 'dev') {
  baseURL = 'https://dev-convergenceapi.moxehealth.com/v1'
}

if (process.env.REACT_APP_ENV === 'test') {
  baseURL = 'https://test-convergenceapi.moxehealth.com/v1'
}

if (process.env.REACT_APP_ENV === 'int') {
  baseURL = 'https://int-convergenceapi.moxehealth.com/v1'
}

if (process.env.REACT_APP_ENV === 'demo') {
  baseURL = 'https://demo-convergenceapi.moxehealth.com/v1'
}

if (process.env.REACT_APP_ENV === 'prod') {
  baseURL = 'https://convergenceapi.moxehealth.com/v1'
}

if (!baseURL) {
  throw Error('Please set REACT_APP_ENV environment variable.')
}

const sessionId = getSessionId()

const API_ROOT = axios.create({
  baseURL,
  timeout: timeout,
  withCredentials: useCreds,
  headers: {
    'x-moxe-session-id': sessionId
  }
})

// Save the session ID to the store
store.dispatch({
  type: actionTypes.SET_SESSION_ID,
  sessionId: sessionId
})

export default API_ROOT

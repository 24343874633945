import React from 'react'
import Component from '../../common/Component'

class EvidenceDocSnippet extends Component {
  render() {
    const {
      text,
      author,
      location
    } = this.props

    const hasAuthor = (author && author != null && author.length > 0)
    const hasLocation = (location && location != null && location.length > 0)

    return (
      <div className='doc-snippet'>
        <div className='snippet'>{text}</div>
        <div className='evidence-provider'>
          {hasAuthor && !hasLocation &&
            <span>{author}</span>
          }
          {!hasAuthor && hasLocation &&
            <span>{location}</span>
          }
          {hasAuthor && hasLocation &&
            <span>{author}, {location}</span>
          }
        </div>
      </div>
    )
  }
}

export default EvidenceDocSnippet

// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../../common/Component'
import ActiveConditions from './ActiveConditions'
import ConfirmedConditions from './ConfirmedConditions'
import RejectedConditions from './RejectedConditions'
import AsyncButton from '../../../common/Button/AsyncButton'

// Action Creators
import { signAndSubmit } from '../../../../store/actions/signAndSubmit'

class SingleStepReview extends Component {

  // Need to group the conditions based on providerKnowledge
  organizeSuspectedConditions(suspectedConditions) {
    console.dir(suspectedConditions)

    let conditions = suspectedConditions.reduce((acc, condition) => {
      let providerKnowledge = condition.providerKnowledge || ''

      if (providerKnowledge.toUpperCase() === 'UNCERTAIN') {
        acc['UNCERTAIN'].push(condition)
      }

      if (providerKnowledge.toUpperCase() === 'KNOWN') {
        acc['KNOWN'].push(condition)
      }

      if (providerKnowledge.toUpperCase() === 'UNKNOWN') {
        acc['UNKNOWN'].push(condition)
      }

      return acc
    },{
      KNOWN: [],
      UNCERTAIN: [],
      UNKNOWN: []
    })

    return {
      knownConditions: conditions['KNOWN'],
      rejectedConditions:[
        ...conditions['UNKNOWN'],
        ...conditions['UNCERTAIN'],
      ]
    }
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'organizeSuspectedConditions'
    ])
  }

  render () {
    let {
      signing,
      reviewText,
      patient,
      documentedConditions,
      suspectedConditions,
    } = this.props.reviewSubmission

    let {
      knownConditions,
      rejectedConditions
    } = this.organizeSuspectedConditions(suspectedConditions)

    let patientHeader = (
      <p>
        No patient information found.
      </p>
    )

    if (patient && patient.fullName && patient.id) {
      patientHeader = (
        <header>
          <h2>
            {patient.fullName || 'No name found.'}
          </h2>

          <p>
            Member ID: {patient.id || 'No Member ID found.'}
          </p>
        </header>
      )
    }

    return (
      <div className='single-step-review'>
        <section className='review-header'>
          <h1>Review of Conditions</h1>

          {patientHeader}
        </section>

        <div className='review-groups'>
          <ActiveConditions conditions={documentedConditions} />
          <ConfirmedConditions conditions={knownConditions} />
          <RejectedConditions conditions={rejectedConditions} />
        </div>

        <footer>
          <p>
            {reviewText}
          </p>

          <AsyncButton
            pending={signing}
            onClick={this.props.onSubmit}
          >
            Sign &amp; Submit
          </AsyncButton>
        </footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  reviewSubmission: state.reviewSubmission
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    signAndSubmit: signAndSubmit
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleStepReview)

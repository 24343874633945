// Modules
import React from 'react'

const CheckMark = (props) => {
  return (
    <div className={`check-mark ${props.className}`} data-condition-state={props.conditionState}>
      <svg width="11px" height="8px" viewBox="0 0 11 8" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g className="check-mark-svg" transform="translate(-548.000000, -356.000000)" strokeWidth="1.5" stroke="#ffffff">
            <g id="Group-2" transform="translate(544.000000, 350.000000)">
              <polyline id="Path-4" points="5.45454545 9.71949897 8.72575833 12.8982126 14.042777 6.66666667"></polyline>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default CheckMark

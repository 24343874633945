// Packages
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faArrowsRotate, faUserClock } from '@fortawesome/free-solid-svg-icons'
import { every } from 'lodash'

// Components
import Component from '../Component'
import RetryableError from './RetryableError'

// Constants
import { RETRYABLE_ERROR_CODES } from '../../../constants/constants'

class ErrorHandler extends Component {
  // Returns the JSX for rendering the h1-level icon for this error
  renderErrorIcon(isRetryable) {
    if (this.props.data.code === 'isTimeoutError') {
      return <FontAwesomeIcon icon={faUserClock} />
    } else if (isRetryable) {
      return <FontAwesomeIcon icon={faArrowsRotate} />
    } else {
      return <FontAwesomeIcon icon={faExclamationTriangle} />
    }
  }

  // Returns true iff an error code matches any of the retryable error types
  isRetryable(errorCode) {
    let found = false

    every(RETRYABLE_ERROR_CODES, code => {
      if (!found && code === errorCode) {
        found = true
        return false // break out of every() loop
      } else {
        return true // check next code
      }
    })

    return found
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'isRetryable',
      'renderErrorIcon'
    ])
  }

  render() {
    const errorData = this.props.data
    const sessionId = this.props.sessionId

    if (!errorData) {
      return null
    }

    // Retryable errors have a different structure, so we use a separate component to handle them
    const isRetryable = this.isRetryable(errorData.code)
  
    let headerText = errorData.message || 'An error has occurred. Please contact your support staff for assistance.'
    let contentClasses = 'notification-content error'
    if (isRetryable) {
      contentClasses += ' retry'
    }

    return (
      <div className='notification-handler'>
        <div className='notification-container'>
          <div className={contentClasses}>
            {this.renderErrorIcon(isRetryable)}

            {isRetryable &&
              <RetryableError errorData={errorData} />
            }

            {!isRetryable &&
              <div className='notification-data'>
                <h1>{headerText}</h1>

                {sessionId &&
                  <div className='session-id'>
                    Session ID:
                    <span>
                      {sessionId}
                    </span>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  reviewSubmission: state.reviewSubmission,
  sessionId: state.authentication.sessionId
})

export default connect(mapStateToProps, null)(ErrorHandler)

import actionTypes from '../actionTypes'
import API_ROOT from '../apiService'

// Action creators
import { addNotification, addError } from '../helpers/notificationHelper'

// Constants
import { NOTIFICATION_CATEGORIES } from '../../constants/constants'

export const setToken = token => {
  return dispatch => {
    dispatch({
        type: actionTypes.LOGIN_AND_GET_PATIENT,
        applications: 'hcc',
        status: 'success',
        token: token
      })
  }
}

export const loginEncryptedGet = queryString => {
  return dispatch => {
    dispatch({type: actionTypes.LOGIN_ENCRYPTED_GET})

    API_ROOT.request({
      method: 'get',
      url: '/authorize/encrypted-get' + queryString,
    }).then(response => {
      dispatch({
        type: actionTypes.LOGIN_ENCRYPTED_GET,
        status: 'success',
        meta: response.data.meta,
        token: response.data.data.token
      })
    }).catch(error => {
      catchErrors(dispatch, actionTypes.LOGIN_ENCRYPTED_GET, error)
    })
  }
}

// Shared function to deal with routing errors the appropriate way
function catchErrors(dispatch, actionType, error) {
  dispatch({
    type: actionType,
    status: 'error',
  })

  // Check for a specific error to display as a notification instead
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.code === 'MEMBER_ID_NOT_FOUND'
  ) {
    addNotification(
      dispatch,
      'Nothing to see here!',
      'This patient is not in scope for this activity. For more information about this tool, please contact your support staff.',
      NOTIFICATION_CATEGORIES.NO_PATIENT_DATA
    )
  } else {
    addError(dispatch, error)
  }
}

import actionTypes from '../actionTypes'
import initialState from '../initialState'

// Set the env.workFlowType and env.columns state, once we have the patientId to base env on
const reducer = (state = initialState.env, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_AND_GET_PATIENT: {
      if (action.meta == null) {
        return state
      }

      return {
        ...state,
        disclaimer: action.meta.disclaimer
      }
    }

    case actionTypes.LOGIN_ENCRYPTED_GET: {
      if (action.meta == null) {
        return state
      }

      return {
        ...state,
        disclaimer: action.meta.disclaimer
      }
    }

    case actionTypes.SET_COLUMNS: {
      let columnCount = 1

      // Ensure column count is an integer that is 1 XOR 2
      if (action.columns === 1 || action.columns === 2) {
        columnCount = action.columns
      }

      return {
        ...state,
        columns: columnCount
      }
    }

    default:
      return state
  }
}

export default reducer

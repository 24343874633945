// Modules
import React from 'react'

const ClinicalNoteIcon = props => {
  return (
    <div className='supporting-evidence-icon clinical-note-icon'>
      <svg width="28px" height="36px" viewBox="0 0 20 20">
        <g stroke="#999999" strokeWidth="1" fill="none" transform="translate(1, 0)">
          <path d="M14.625,9.15522666 L14.625,12.5859457 L14.625,17.5427106 C14.625,17.7944296 14.4148707,18 14.1551724,18 L0.469827586,18 C0.21012931,18 0,17.7944296 0,17.5427106 L0,0.45728936 C0,0.204521618 0.21012931,0 0.469827586,0 L14.1551724,0 C14.4148707,0 14.625,0.204521618 14.625,0.45728936 L14.625,2.70912481" id="Stroke-1"></path>
          <g transform="translate(6.825000, 3.789474)">
            <polygon points="2.80158354 7.04086801 10.725 1.73680059 9.32366126 0 1.4002448 5.30406743 0 7.57894737"></polygon>
            <path d="M7.8,0.947368421 L8.775,2.84210526 L7.8,0.947368421 Z" transform="translate(8.287500, 1.894737) rotate(-8.000000) translate(-8.287500, -1.894737) "></path>
            <path d="M2.08094946,4.79354648 L3.05594946,6.68828332 L2.08094946,4.79354648 Z" id="Stroke-7" transform="translate(2.568449, 5.740915) rotate(-8.000000) translate(-2.568449, -5.740915) "></path>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default ClinicalNoteIcon

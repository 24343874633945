// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'

// Action Creators
import { updateCondition, saveCondition } from '../../../store/actions/conditions'

// Constants
import { SINGLE_STEP_TYPES } from '../../../constants/constants'

class NoteStep extends Component {

  // Called when note field text is changed.
  // TODO: Debounce this function so that it doesn't updateCondition per keystroke
  onNoteTextChange(noteText) {
    const condition = this.props.condition

    let updatedCondition = {
      ...condition,
      optionSelected: {
        ...condition.optionSelected,
        [condition.currentStep.name]: noteText
      }
    }

    // Update Redux state
    this.props.updateCondition(updatedCondition)
  }

  constructor(props) {
    super(props)

    this._bindMethods([
      'onNoteTextChange'
    ])
  }

  render() {
    const {
      condition
    } = this.props

    if (condition == null || condition.currentStep == null) {
      return null
    }

    return (
      <form className='single-step single-step-note'>
        <header>
          <h2>
            {condition.currentStep.title}
          </h2>
        </header>

        <div className='reason-note'>
          <textarea
            rows='6'
            autoFocus
            className={condition.stylingClasses}
            value={condition.optionSelected[SINGLE_STEP_TYPES.NOTE] || ''}
            onChange={e => {this.onNoteTextChange(e.target.value)}}
          />
        </div>
      </form>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateCondition: updateCondition,
    saveCondition: saveCondition
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(NoteStep)

// Packages
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Components
import Component from '../../common/Component'
import SuccessDialog from '../../common/Dialog/SuccessDialog'
import ReviewModal from '../../common/Dialog/ReviewModal'
import AsyncButton from '../../common/Button/AsyncButton'
import ConditionList from './ConditionList'
import HccDisclaimer from './HccDisclaimer'
import MultiStepReview from '../MultiStepWorkflow/Review/MultiStepReview'
import SingleStepReview from '../SingleStepWorkflow/Review/SingleStepReview'
import Submitted from './Submitted'
import ContinueToReview from './ContinueToReview'

// Action Creators
import { getHcc } from '../../../store/actions/getHcc'
import {
  submitForReview,
  saveConditionAndSubmitForReview,
  signAndSubmit,
  closeReview,
  closeSubmissionModal
} from '../../../store/actions/signAndSubmit'

class HccContainer extends Component {

  // Determine which review components to use in the one-column modal view. Two-column review
  // components are handled up the tree in App, and are placed in the secondary column (right side).
  getReviewComponent(recommendedWorkflow) {
    if (recommendedWorkflow) {
      return (<MultiStepReview onSubmit={this.props.signAndSubmit} />)
    } else {
      return (<SingleStepReview onSubmit={this.props.signAndSubmit} />)
    }
  }

  // Add a class to the .hcc-content section, so that it adds some padding for the footer overlay
  getContentClassNames(showProgressBar) {
    let contentClassNames = 'hcc-content footer-present'

    if (showProgressBar) {
      contentClassNames += ' with-progress-bar'
    }

    return contentClassNames
  }

  // Based on Redux state changes, should we submit the conditions for review or not?
  shouldSubmitForReview(props, nextProps) {
    // allCompleted changed from false => true. If the user re-opens a completed condition while
    // Review Conditions is open, allCompleted is set to false. When that condition is completed or
    // closed again, allCompleted is again set to true.
    return !props.allCompleted && nextProps.allCompleted && props.canSubmitForReview
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // React component lifecycle functions
  //////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(props) {
    super(props)

    this._bindMethods([
      'shouldSubmitForReview',
      'getReviewComponent',
      'getContentClassNames'
    ])

    if (props.allCompleted) {
      props.submitForReview()
    }
  }

  componentDidMount() {
    if (!this.props.fetchingHCC && !this.props.fetchedHCC) {
      this.props.getHcc()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.shouldSubmitForReview(this.props, nextProps)) {
      this.props.submitForReview()
    }
  }

  render() {
    const {
      documentedConditions,
      recommendedConditions,
      suspectedConditions,
      ciConditions,
      recommendedWorkflow,
      env,
      closeReview,
      closeSubmissionModal,
    } = this.props

    const {
      isReviewingConditions,
      signed,
      isSubmissionModalOpen
    } = this.props.reviewSubmission

    // TODO: Use propTypes for validation
    if (documentedConditions.list.length === 0 && recommendedConditions.list.length === 0 && suspectedConditions.list.length === 0 && ciConditions.list.length === 0) {
      return null
    }

    if (recommendedWorkflow && signed) {
      return (
        <Submitted />
      )
    }

    let reviewComponent = this.getReviewComponent(recommendedWorkflow)

    return (
      <section className='hcc'>
        <div className={this.getContentClassNames(recommendedWorkflow)}>
          {recommendedConditions.list && recommendedConditions.list.length > 0 &&
            <section className='condition-list-container recommended'>
              <HccDisclaimer disclaimer={env.disclaimer} />
              <ConditionList conditions={recommendedConditions} />
            </section>
          }

          {documentedConditions.list && documentedConditions.list.length > 0 &&
            <section className='condition-list-container documented'>
              <header className='h1 condition-header'>
                Documented Conditions
              </header>
              <ConditionList conditions={documentedConditions} />
            </section>
          }

          {suspectedConditions.list && suspectedConditions.list.length > 0 &&
            <section className='condition-list-container suspected'>
              <header className='h1 condition-header'>
                Suspected Conditions
              </header>
              <ConditionList conditions={suspectedConditions} />
            </section>
          }

          {ciConditions.list && ciConditions.list.length > 0 &&
          <section className='condition-list-container'>
            <header className='h1 condition-header'>
             Clinical Inference
            </header>
            <HccDisclaimer disclaimer={env.disclaimer} />
            <ConditionList conditions={ciConditions} />
          </section>
          }
        </div>

        <ContinueToReview showProgressBar={recommendedWorkflow} />

        {env.columns === 1 && isReviewingConditions &&
          <ReviewModal isOpen={isReviewingConditions} onClose={closeReview}>
            {reviewComponent}
          </ReviewModal>
        }

        {!recommendedWorkflow && isSubmissionModalOpen &&
          <SuccessDialog
            isOpen={isSubmissionModalOpen}
            onClose={closeSubmissionModal}
          >
            <h1>File has been added to chart.</h1>
            <AsyncButton onClick={closeSubmissionModal}>
              OK
            </AsyncButton>
          </SuccessDialog>
        }
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  documentedConditions: state.conditions.documented,
  recommendedConditions: state.conditions.recommended,
  suspectedConditions: state.conditions.suspected,
  ciConditions: state.conditions.clinicalinference,
  recommendedWorkflow: state.conditions.recommendedWorkflow,
  allCompleted: state.conditions.allCompleted,
  fetchingHCC: state.conditions.fetching,
  fetchedHCC: state.conditions.fetched,
  canSubmitForReview: state.conditions.canSubmitForReview,
  reviewSubmission: state.reviewSubmission,
  env: state.env,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getHcc: getHcc,
    signAndSubmit: signAndSubmit,
    submitForReview: submitForReview,
    saveConditionAndSubmitForReview: saveConditionAndSubmitForReview,
    closeReview: closeReview,
    closeSubmissionModal: closeSubmissionModal
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HccContainer)
